import { AppEpic } from '../../../../app/store';
import { filter, map, withLatestFrom } from 'rxjs';
import { LayoutSidebarActions } from '../actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { ofType } from 'redux-observable';

export const sideBarCloseEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    withLatestFrom(state$),
    map(([, state]) => state.sidebar.isOpen),
    filter((isOpen) => !!isOpen),
    map(() => LayoutSidebarActions.closeSideBar())
  );
