import { Alert, Box, Button, Snackbar, SwipeableDrawer } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LayoutActions, LayoutSidebarActions, LayoutSnackbarActions } from './store/actions';
import Header from './header/Header';
import { LayoutSelectors, LayoutSidebarSelectors, LayoutSnackbarSelectors } from './store/selectors';
import { BarcodeSelectors, ShopsSelectors } from '../scanner/store/selectors';
import SideBarContent from './sidebar-content/SideBarContent';
import styles from './Layout.module.scss';
import { SideBarItemModel } from './models/SideBarItemModel';
import { push } from 'connected-react-router';
import { NewsSelectors } from '../news/store/selectors';
import React, { ReactNode, useEffect, useState } from 'react';
import { NewsActions } from '../news/store/actions';
import { Footer } from './footer/Footer';
import { BarcodeActions, ShopsActions } from '../scanner/store/actions';
import { PaymentActions } from '../payment/store/actions';
import { DevSupportSelectors } from '../dev-support/store/selectors';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useWindowSize } from './hooks/hooks';

interface Props {
  children?: ReactNode;
}

const Layout: React.FC<Props> = (props: Props) => {
  const items = useAppSelector(LayoutSidebarSelectors.getSideBarItems);
  const isSideBarOpen = useAppSelector(LayoutSidebarSelectors.isSideBarOpen);
  const isSnackBarShown = useAppSelector(LayoutSnackbarSelectors.isSnackbarShown);
  const snackMessage = useAppSelector(LayoutSnackbarSelectors.getCurrentMessage);
  const currentShop = useAppSelector(ShopsSelectors.getCurrentShop);
  const unreadNewsCount = useAppSelector(NewsSelectors.getUnreadNewsCount);
  const footerTitle = useAppSelector(LayoutSelectors.getFooterTitle);
  const footerSubTitle = useAppSelector(LayoutSelectors.getFooterSubTitle);
  const footerCartButtonEnabled = useAppSelector(LayoutSelectors.getFooterCartButtonEnabled);
  const footerHidden = useAppSelector(LayoutSelectors.isFooterHidden);
  const isCalculationInProgress = useAppSelector(LayoutSelectors.isCalculationInProgress);
  const devSupport = useAppSelector(DevSupportSelectors.getDevSupport);
  const [emptyBasketDialogOpen, setEmptyBasketDialogOpen] = useState(false);
  const products = useAppSelector(BarcodeSelectors.getAllProductsDescending);
  const [width] = useWindowSize();
  const [displayContent, setDisplayContent] = useState(Boolean(localStorage.getItem('displayContent')) || width < 875);

  useEffect(() => {
    if (width < 875) {
      setDisplayContent(true);
    } else if (width > 875 && Boolean(localStorage.getItem('displayContent'))) {
      setDisplayContent(true);
    } else {
      setDisplayContent(false);
    }
  }, [width]);

  const handleCloseEmptyBasketDialog = () => {
    setEmptyBasketDialogOpen(false);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!unreadNewsCount) dispatch(NewsActions.loadNews());
  });

  const toggleSideBar = () => {
    if (!isSideBarOpen) dispatch(LayoutSidebarActions.openSideBar());
    else dispatch(LayoutSidebarActions.closeSideBar());
  };

  const handleSnackBarClose = () => {
    dispatch(LayoutSnackbarActions.hideSnackbar());
  };

  const handleShopClick = () => {
    dispatch(ShopsActions.openShopsPanel());
  };

  const routeToPage = (itemModel: SideBarItemModel) => {
    console.log(itemModel.route);
    if (itemModel && itemModel.route) {
      console.log({ title: itemModel.title });
      dispatch(push(itemModel.route, { title: itemModel.title }));
    }
  };

  const handleCartButtonClick = () => {
    if (products.length === 0) {
      setEmptyBasketDialogOpen(true);
      return;
    }
    if (devSupport.skipPayment) {
      dispatch(PaymentActions.skipPayment());
      dispatch(LayoutActions.hideConfirmCartDialog());
    } else {
      dispatch(PaymentActions.loadPaymentSessionUrl());
    }
    dispatch(LayoutActions.hideConfirmCartDialog());
    dispatch(LayoutActions.showSpinner(true));
    //clear basket when prompted to pay
    dispatch(BarcodeActions.clearBasket());
  };

  const handleBrowserButtonAction = () => {
    localStorage.setItem('displayContent', 'true');
    setDisplayContent(true);
  };

  const BrowserBackground: React.FC = () => {
    return (
      <Box className={styles.browserWrapper}>
        <Box className={styles.browserMessageBox}>
          <Box className={styles.browserMessageWrapper}>
            <Box className={styles.browserIcon}>
              <WarningAmberIcon />
            </Box>
            <Box component="p" className={styles.browserMessage}>
              Please open this app on your mobile phone <br />
              to get the full Sprinting ScanGo experience.
            </Box>
          </Box>
          <Box className={styles.browserMessageAction}>
            <Button onClick={handleBrowserButtonAction}>Continue</Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return displayContent ? (
    <Box className="appContent">
      <Header pos="relative" title="ScanGo" />
      <Box className={footerHidden ? styles.innerContentNoFooter : styles.innerContent}>{props.children}</Box>
      <Footer
        className={styles.footer}
        onMenuClick={toggleSideBar}
        title={footerTitle}
        subTitle={footerSubTitle}
        onCartButtonClick={handleCartButtonClick}
        isCartButtonEnabled={footerCartButtonEnabled}
        isCalculationInProgress={isCalculationInProgress}
        isHidden={footerHidden}
        handleCloseEmptyBasketDialog={handleCloseEmptyBasketDialog}
        emptyBasketDialogOpen={emptyBasketDialogOpen}
      />

      <Snackbar open={isSnackBarShown} autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert severity="warning">{snackMessage}</Alert>
      </Snackbar>
      <SwipeableDrawer
        anchor="left"
        disableSwipeToOpen={true}
        open={isSideBarOpen}
        classes={{
          paper: styles.drawerPaper,
        }}
        onClose={toggleSideBar}
        onOpen={toggleSideBar}
      >
        <div className={styles.sidebarContentContainer}>
          <SideBarContent
            className={styles.sidebarContent}
            onShopClick={handleShopClick}
            currentShop={currentShop}
            onItemClick={routeToPage}
            items={items}
          />
        </div>
      </SwipeableDrawer>
    </Box>
  ) : (
    <BrowserBackground />
  );
};

export default Layout;
