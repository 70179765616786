import styles from './PaymentApproved.module.scss';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PaymentActions } from '../store/actions';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { SystemHelpers } from '../../../app/helpers';
import { PaymentSelectors } from '../store/selectors';

export const PaymentApproved: React.FC = () => {
  const [tempBarcode, setTempBarcode] = useState('');
  const countdown = useAppSelector(PaymentSelectors.getPaymentFinishCountdown);
  const dispatch = useAppDispatch();

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(PaymentActions.cancelQRCodeTimer());
    dispatch(push('/payment-finished'));
  };

  useEffect(() => {
    setTempBarcode(SystemHelpers.getTempReceiptBarcode()!);
    dispatch(PaymentActions.finishPaymentTimerRedirect());
  }, [dispatch]);

  return (
    <Box className={styles.container}>
      <Box className={styles.status}>
        <Box className={styles.placeholder} />
        <Box className={styles.statusText}>Payment Approved</Box>
        <Box component="a" href="/" onClick={handleClose} className={styles.closeButton}>
          <CloseIcon />
        </Box>
      </Box>
      <Box className={styles.qrCode}>
        <QRCode value={tempBarcode} />
      </Box>
      <Box className={styles.infoText}>
        <Box>The code is valid for</Box>
      </Box>
      <Box className={styles.infoTextTime}>{countdown} seconds</Box>

      <Box className={styles.infoText}>
        <Box>
          Scan the QR code on the <br />
          gate to leave the store
        </Box>
      </Box>
    </Box>
  );
};
