import Ripples from 'react-ripples';
import './SideBarItem.scss';
import { SideBarItemModel } from '../models/SideBarItemModel';
import { useAppSelector } from '../../../app/hooks';
import { NewsSelectors } from '../../news/store/selectors';
import React from 'react';

interface Props {
  sidebarItem: SideBarItemModel;
  onClick: (item: SideBarItemModel) => void;
}

export const SideBarItem: React.FC<Props> = (props: Props) => {
  const handleClick = () => (props.onClick ? props.onClick(props.sidebarItem) : undefined);

  const unreadNewsNotification = useAppSelector(NewsSelectors.getUnreadNewsCount);

  const hasNotifications = () => !!(props.sidebarItem.route === '/news' && unreadNewsNotification && unreadNewsNotification !== 0);

  return (
    <Ripples className="side-bar-item" onClick={handleClick}>
      <div className="side-bar-item-left">
        <img className="side-bar-item-icon" src={props.sidebarItem.icon} alt="Sidebar item icon" />
        <span>{props.sidebarItem.title}</span>
        {hasNotifications() && <div className="side-bar-item-notification">{unreadNewsNotification}</div>}
      </div>
      <img className="side-bar-item-icon-right material-icons" src={props.sidebarItem.rightSideIcon} alt="Sidebar item action icon" />
    </Ripples>
  );
};
