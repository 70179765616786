import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import styles from './RemoveProductDialog.module.scss';
import DeleteIcon from '../scan-list/DeleteIcon';
import { Product } from '../../../app/models/Product';
import SagButton from '../../../components/sag-button/SagButton';
import React from 'react';

interface Props {
  open: boolean;
  product: Product | undefined;
  onCloseClick: () => void;
  onApply: () => void;
}

export const RemoveProductDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.onCloseClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.dialog}
    >
      <DialogTitle id="alert-dialog-title">
        <Box className={styles.title}>
          <DeleteIcon />
          <Box className={styles.titleText}>Remove {props.product ? props.product.description : 'product'} from your shopping cart</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box className={styles.content} component="span">
            <Box component="span">
              Are you sure you want to remove &quot;{props.product ? props.product.description : 'product'}&quot; from your cart?
            </Box>
            <Box component="span" className={styles.text}>
              Remember to put the item back where you took it.
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className={styles.actions}>
          <SagButton className={styles.actionButton} onClick={() => props.onCloseClick()} value="Cancel" outlined={true} />
          <SagButton
            className={styles.actionButton}
            onClick={() => {
              props.onCloseClick();
              props.onApply();
            }}
            value="Remove"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
