import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { sidebarReducer } from '../features/layout/store/reducers/sidebar.reducers';
import testReducer from './reducer';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { BarcodeReducers, ShopsReducers } from '../features/scanner/store/reducers';
import { createEpicMiddleware, Epic } from 'redux-observable';
import storeLogger from './store-logger';
import { LayoutReducers, LayoutSnackbarReducers } from '../features/layout/store/reducers';
import rootEpic from './epics';
import { SplashReducers } from '../features/splash-screen/store/reducers';
import { CardsReducers } from '../features/cards/store/reducers';
import { ReceiptsReducers } from '../features/receipts/store/reducers';
import { NewsReducers } from '../features/news/store/reducers';
import { PaymentReducers } from '../features/payment/store/reducers';
import { DevSupportReducers } from '../features/dev-support/store/reducers';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState>();

const reducer = combineReducers({
  count: testReducer,
  sidebar: sidebarReducer,
  scanner: BarcodeReducers.scannerReducer,
  shops: ShopsReducers.shopsReducer,
  snackbar: LayoutSnackbarReducers.snackbarReducer,
  layout: LayoutReducers.layoutReducer,
  splash: SplashReducers.splashReducer,
  cards: CardsReducers.cardsReducer,
  receipts: ReceiptsReducers.receiptsReducer,
  news: NewsReducers.newsReducer,
  payment: PaymentReducers.paymentReducer,
  devSupport: DevSupportReducers.devSupportReducer,
  router: connectRouter(history),
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
    }),
    routerMiddleware(history),
    epicMiddleware,
    storeLogger,
  ],
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof reducer>;
export type AppEpic = Epic<AnyAction, AnyAction, AppState>;
export type AppStore = typeof store;
