import { createReducer } from '@reduxjs/toolkit';
import { BarcodeActions } from '../actions';
import { Product } from '../../../../app/models/Product';
import { ForUpdate } from '../../../../app/models/ForUpdate';
import { Basket } from '../../../../app/models/Basket';
import { DateTime } from 'luxon';

const LOCAL_STORAGE_ID = 'basket';

const getInitialState = (): Basket => {
  try {
    const basket = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)!);
    if (basket) {
      const clearAfterXInactiveHours = 72;
      const now = DateTime.now();
      const basketUpdatedAt = DateTime.fromISO(basket.updatedAt);
      const diffInHours = now.diff(basketUpdatedAt, 'hours').toObject().hours;
      if (diffInHours && diffInHours > clearAfterXInactiveHours) {
        localStorage.removeItem(LOCAL_STORAGE_ID);
      } else {
        return basket;
      }
    }
  } catch (e) {}

  return {
    id: '',
    items: [],
    clientTraceId: '',
    totalPrice: 0,
    storeId: '00000000-0000-0000-0000-000000009999',
    tax: 0,
    employeeId: '',
    lastBasketEventId: '',
    taxAfterDiscount: 0,
    totalBasketPrice: 0,
    paymentRefId: '',
    tempReceiptBarCode: '',
  };
};

const initialState = getInitialState();

const updateOneProduct = (state: Product[], updated: ForUpdate<Product>): Product[] => {
  let updateProducts = JSON.parse(JSON.stringify(state));
  const updatedIndex = state.findIndex((p) => p.ean === updated.id);
  for (const key in updated.changes) {
    updateProducts[updatedIndex][key] = updated.changes[key];
  }
  return updateProducts;
};

export const scannerReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(BarcodeActions.barcodeFulfilled, (state, { payload: basket }) => {
      localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify(basket));
      return {
        ...basket,
      };
    })
    .addCase(BarcodeActions.putBarcode, (state, { payload: barcode }) => ({
      ...state,
      items: [
        ...state.items,
        {
          ean: barcode,
          quantity: 1,
        },
      ],
    }))
    .addCase(BarcodeActions.barcodeRepeated, (state, { payload: product }) => ({
      ...state,
      items: updateOneProduct(state.items, {
        id: product.ean,
        changes: {
          quantity: product.quantity + 1,
        },
      }),
    }))
    .addCase(BarcodeActions.barcodeDecrementCount, (state, { payload: product }) => ({
      ...state,
      items: updateOneProduct(state.items, {
        id: product.ean,
        changes: {
          quantity: product.quantity - 1,
        },
      }),
    }))
    .addCase(BarcodeActions.barcodeRemove, (state, { payload: product }) => ({
      ...state,
      items: state.items.filter((p) => p.ean !== product.ean),
    }))
    .addCase(BarcodeActions.clearBasket, (state) => {
      localStorage.removeItem(LOCAL_STORAGE_ID);
      return {
        ...state,
        items: [],
        totalPrice: 0,
        totalBasketPrice: 0,
      };
    })
    .addCase(BarcodeActions.rejectPostBarcode, (state) => ({
      ...state,
      items: state.items.slice(0, state.items.length - 1),
    }))
);
