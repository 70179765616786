import { filter, mergeMap, switchMap } from 'rxjs';
import { AppEpic } from '../../../../app/store';
import { NewsActions } from '../actions';
import { NewsService } from '../../../../services';

export const loadNewsEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(NewsActions.loadNews.match),
    switchMap(() =>
      NewsService.loadNews().pipe(mergeMap((response) => [NewsActions.newsFulfilled(response.data), NewsActions.countUnreadNews()]))
    )
  );
