import { createAction } from '@reduxjs/toolkit';
import { Shop } from '../../../../app/models/shop/Shop';
import { featureKey } from '../../../layout';

export const shopsFulfilled = createAction<Shop[]>(featureKey + '/shopsFulfilled');

export const setCurrentShop = createAction<Shop>(featureKey + '/setCurrentShop');

export const getSupportedShops = createAction(featureKey + '/getSupportedShops');

export const openShopsPanel = createAction(featureKey + '/openShopsPanel');

export const closeShopsPanel = createAction(featureKey + '/closeShopsPanel');
