import { Box, Button, Dialog, DialogActions, DialogContent, List, ListItem } from '@mui/material';
import React from 'react';
import style from './InfoDialog.module.scss';
import mockData from './data/infoDialogData.json';
import barcode_1 from './assets/1.png';
import barcode_2 from './assets/2.png';
import barcode_3 from './assets/3.png';
import barcode_4 from './assets/4.png';
import barcode_5 from './assets/5.png';
import barcode_6 from './assets/6.png';
import barcode_7 from './assets/7.png';

interface Props {
  open: boolean;
  onCloseClick: () => void;
  onApply: (barcode: string) => void;
}

export const InfoDialog = (props: Props) => {
  const [selectedElement, setSelectedElement] = React.useState<number>(-1);

  const onClose = () => {
    props.onCloseClick();
  };

  const onSubmit = () => {
    if (selectedElement > -1) {
      props.onApply(mockData[selectedElement].ean);
      onClose();
    }
  };

  const onItemClick = (i) => {
    if (selectedElement === i) {
      setSelectedElement(-1);
    } else {
      setSelectedElement(i);
    }
  };

  const getImage = (i) => {
    switch (i) {
      case 0:
        return <img src={barcode_1} alt="barcode" className={style.barcodeImage} />;
      case 1:
        return <img src={barcode_2} alt="barcode" className={style.barcodeImage} />;
      case 2:
        return <img src={barcode_3} alt="barcode" className={style.barcodeImage} />;
      case 3:
        return <img src={barcode_4} alt="barcode" className={style.barcodeImage} />;
      case 4:
        return <img src={barcode_5} alt="barcode" className={style.barcodeImage} />;
      case 5:
        return <img src={barcode_6} alt="barcode" className={style.barcodeImage} />;
      case 6:
        return <img src={barcode_7} alt="barcode" className={style.barcodeImage} />;
    }
  };

  return (
    <Dialog open={props.open} onClose={() => onClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <form noValidate>
        <DialogContent>
          <List className={style.listWrapper}>
            {mockData.map((x, i) => {
              const listItemClass = selectedElement === i ? style.selectedItem : style.listItem;
              return (
                <ListItem key={i} className={listItemClass} onClick={() => onItemClick(i)}>
                  <Box className={style.listItemInner}>
                    <Box>
                      {i + 1 + '.'}
                      <b>{x.name}</b>
                    </Box>
                    <Box>{'-EAN' + x.ean}</Box>
                  </Box>
                  <Box>{getImage(i)}</Box>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button autoFocus onClick={() => onSubmit()}>
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
