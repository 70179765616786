export const PRIMARY_CARD_KEY = 'PRIMARY_CARD_KEY';

export const cachePrimaryCard = (id: string) => {
  localStorage.setItem(PRIMARY_CARD_KEY, id);
};

export const getPrimaryCardIdFromCache = (): string => {
  const cardId = localStorage.getItem(PRIMARY_CARD_KEY);
  return cardId ? cardId : '';
};

// eslint-disable-next-line no-restricted-globals
export const getCardFormUrl = () => `/card/session?paymentMeans=NETAXEPT&language=en-GB&redirectUrl=${location.origin}/netaxept`;
