import { BarcodePicker, ScanSettings } from 'scandit-sdk';
import InfoIcon from '@mui/icons-material/Info';
import styles from './BarcodeScanner.module.scss';
import ScanditBarcodeScanner from 'scandit-sdk-react';
import { Button } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { DevSupportSelectors } from '../../dev-support/store/selectors';
import img from './Vector.png';

interface Props {
  scanSettings: ScanSettings;
  cameraAccess: boolean;
  onScan: (data) => void;
  handleInputDialogOpen?: () => void;
  handleInfoDialogOpen?: () => void;
  isInputDialogOpen?: () => void;
}

const BarcodeScanner: React.FC<Props> = (props: Props) => {
  const devSupport = useAppSelector(DevSupportSelectors.getDevSupport);
  const licenseKey = process.env.REACT_APP_SCANDIT_API_KEY;
  const engineLocation = process.env.REACT_APP_SCANDIT_ENGINE_LOCATION;
  return (
    <div className={styles.barcodeContainer}>
      <ScanditBarcodeScanner
        accessCamera={props.cameraAccess}
        licenseKey={licenseKey}
        engineLocation={engineLocation}
        playSoundOnScan={true}
        onScan={props.onScan}
        scanSettings={props.scanSettings}
        viewfinderArea={{ x: 0, y: 0, width: 1, height: 1 }}
        enableCameraSwitcher={false}
        enableTapToFocus={true}
        paused={!props.cameraAccess}
        preloadBlurryRecognition={true}
        videoFit={BarcodePicker.ObjectFit.COVER}
      />
      <div className={styles.barcodeWrapper}>
        <div className={styles.searchArea}></div>
        {devSupport.calculateLocally && (
          <Button className={styles.infoButton} onClick={() => (props.handleInfoDialogOpen ? props.handleInfoDialogOpen() : null)}>
            <InfoIcon className={styles.infoIcon} />
          </Button>
        )}
        <Button
          className={styles.manuallyEnteredButton}
          onClick={() => (props.handleInputDialogOpen ? props.handleInputDialogOpen() : null)}
        >
          <img src={img} className={styles.img} /> ENTER BARCODE MANUALLY
        </Button>
      </div>
    </div>
  );
};

export default BarcodeScanner;
