import { createReducer } from '@reduxjs/toolkit';
import { SplashActions } from '../actions';

interface SplashState {
  hasCameraPermission: boolean | undefined;
}

const initState: SplashState = {
  hasCameraPermission: undefined,
};

export const splashReducer = createReducer(initState, (builder) =>
  builder.addCase(SplashActions.setCameraPermission, (state, { payload }) => {
    return {
      hasCameraPermission: payload,
    };
  })
);
