import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import styles from './EmptyBasketDialog.module.scss';

interface Props {
  open: boolean;
  onCloseClick: () => void;
}
export const EmptyBasketDialog = (props: Props) => {
  const onClose = () => {
    props.onCloseClick();
  };

  return (
    <Dialog
      open={props.open}
      className={styles.dialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box>
        <DialogTitle className={styles.title} id="alert-dialog-title">
          Your basket is empty
        </DialogTitle>
        <DialogContent>
          <Box component="p" className={styles.dialogContent}>
            To make a purchase, please put at least one item in your basket
          </Box>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button className={styles.actionButton} onClick={onClose}>
            Okay
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
