import ScanList from './scan-list/ScanList';
import BarcodeScanner from './barcode-scanner/BarcodeScanner';
import styles from './Scanner.module.scss';
import { DefaultScanSettingsProvider } from '../../app/scan-settings/default-scan-settings.provider';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ScanResult } from 'scandit-sdk';
import { store } from '../../app/store';
import { BarcodeActions, ShopsActions } from './store/actions';
import { BarcodeSelectors, ShopsSelectors } from './store/selectors';
import { Product } from '../../app/models/Product';
import { RemoveProductDialog } from './remove-product-dialog/RemoveProductDialog';
import React, { useEffect, useState } from 'react';
import SplashEmptyScreen from '../splash-screen/empty-collection/SplashEmptyScreen';
import { hasCameraAccess } from '../../app/utils/permissions.utils';
import { SplashActions } from '../splash-screen/store/actions';
import { SplashSelectors } from '../splash-screen/store/selectors';
import CameraAccessScreen from '../splash-screen/access-camera/CameraAccessScreen';
import ManualInputDialog from './manual-input-dialog/ManualInputDialog';
import { ShopList } from './shop-list/ShopList';
import { LayoutActions } from '../layout/store/actions';
import { LayoutSelectors } from '../layout/store/selectors';
import { PurchaseConfirmDialog } from './purchase-confirm-dialog/PurchaseConfirmDialog';
import { PaymentActions } from '../payment/store/actions';
import { DevSupportSelectors } from '../dev-support/store/selectors';
import { Box, Button, CircularProgress } from '@mui/material';
import { InfoDialog } from './info-dialog/InfoDialog';
import { handleProductsChange } from './scanner.helper';

const Scanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const scanSettings = new DefaultScanSettingsProvider().get();
  const products = handleProductsChange(useAppSelector(BarcodeSelectors.getAllProductsDescending));

  const totalBasketPrice = useAppSelector(BarcodeSelectors.getTotalPrice);
  const totalProductsCount = useAppSelector(BarcodeSelectors.getAllProductsCount);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const cameraAccess = useAppSelector(SplashSelectors.hasCameraPermission);
  const [deletingProduct, setDeletingProduct] = useState<Product | undefined>(undefined);
  const shopDialogOpen = useAppSelector(ShopsSelectors.shopsPanelOpen);
  const isConfirmCartDialogVisible = useAppSelector(LayoutSelectors.isConfirmCartDialogVisible);
  const devSupport = useAppSelector(DevSupportSelectors.getDevSupport);
  const showSpinner = useAppSelector(LayoutSelectors.showSpinner);

  const [isAppVisible, setIsAppVisible] = useState(true);
  useEffect(() => {
    // Listen for the visibilitychange event
    document.addEventListener('visibilitychange', (e) => {
      // Check if the app is now visible and the user is returning to the app
      const state = (e.target as any).visibilityState;
      if (state === 'visible') {
        setIsAppVisible(true);
      } else {
        setIsAppVisible(false);
      }
    });
  }, []);

  useEffect(() => {
    if (totalProductsCount !== 0) {
      dispatch(LayoutActions.setFooterTitle(`Total kr. ${parseFloat((totalBasketPrice / 100).toString()).toFixed(2)}`));
      dispatch(LayoutActions.setFooterSubTitle(`${totalProductsCount} items in the basket`));
    } else {
      dispatch(LayoutActions.setFooterTitle('Your basket is empty'));
      dispatch(LayoutActions.setFooterSubTitle('Scan your items'));
    }
  }, [dispatch, totalBasketPrice, totalProductsCount]);

  useEffect(() => {
    dispatch(LayoutActions.setFooterCartButtonEnabled(true));
    return () => {
      dispatch(LayoutActions.setFooterCartButtonEnabled(false));
      dispatch(LayoutActions.setFooterTitle(''));
      dispatch(LayoutActions.setFooterSubTitle(''));
    };
  }, [dispatch]);

  useEffect(() => {
    //if user is prompted back to the app, we need to remove spinner
    dispatch(LayoutActions.showSpinner(false));
  }, []);

  if (cameraAccess === undefined)
    hasCameraAccess().then((value) => {
      console.log('access checked');
      dispatch(SplashActions.setCameraPermission(value));
    });

  const onDecrementCountClick = (p: Product) => {
    dispatch(LayoutActions.setCalculationInProgress(true));
    if (p.quantity > 1) dispatch(BarcodeActions.barcodeDecrementCount(p));
    else if (p.quantity === 1) dispatch(BarcodeActions.barcodeRemove(p));
  };

  const onIncrementCountClick = (p: Product) => {
    dispatch(BarcodeActions.barcodeIncrementCount(p));
    dispatch(LayoutActions.setCalculationInProgress(true));
  };

  const onDeleteProductClick = (p: Product) => {
    setRemoveDialogOpen(true);
    setDeletingProduct(p);
  };

  const onRemoveDialogApply = () => {
    if (deletingProduct) {
      dispatch(BarcodeActions.barcodeRemove(deletingProduct));
    }
  };

  const onRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
  };

  const handleManualInputClose = () => {
    setInputDialogOpen(false);
  };

  const handleManualInputOpen = () => {
    setInputDialogOpen(true);
  };

  const handleInfoClose = () => {
    setInfoDialogOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoDialogOpen(true);
  };

  const handleManualInputSubmit = (barcode: string) => {
    dispatch(BarcodeActions.putBarcode(barcode));
  };

  const handleCloseShopsPanel = () => {
    dispatch(BarcodeActions.rejectPostBarcode());
  };

  const onScan = (scanResult: ScanResult) => {
    const barcode = scanResult.barcodes[0].data;
    const existingProduct = BarcodeSelectors.getByBarcode(barcode)(store.getState());
    if (existingProduct) {
      dispatch(BarcodeActions.barcodeRepeated(existingProduct));
    } else {
      dispatch(BarcodeActions.putBarcode(barcode));
    }
  };

  const handleCartDialogClose = () => {
    dispatch(LayoutActions.hideConfirmCartDialog());
  };

  const handleCartDialogConfirm = () => {
    if (devSupport.skipPayment) {
      dispatch(PaymentActions.skipPayment());
      dispatch(LayoutActions.hideConfirmCartDialog());
    } else {
      dispatch(PaymentActions.loadPaymentSessionUrl());
    }
    dispatch(LayoutActions.hideConfirmCartDialog());
    dispatch(LayoutActions.showSpinner(true));
  };

  const clearBasketHandler = () => {
    dispatch(BarcodeActions.clearBasket());
  };

  return showSpinner ? (
    <CircularProgress className={styles.spinner} />
  ) : (
    <Box className={styles.scannerContainer}>
      <Box className={styles.scannerSection}>
        <BarcodeScanner
          scanSettings={scanSettings}
          onScan={onScan}
          cameraAccess={isAppVisible && !!cameraAccess}
          handleInputDialogOpen={() => handleManualInputOpen()}
          handleInfoDialogOpen={() => handleInfoOpen()}
        />
      </Box>
      <Box className={styles.listSection}>
        {cameraAccess === undefined ? (
          <SplashEmptyScreen />
        ) : !cameraAccess ? (
          <CameraAccessScreen />
        ) : products && products.length > 0 ? (
          <>
            <ScanList
              products={products}
              onDeleteProductClick={(product) => onDeleteProductClick(product)}
              onDecrementCountClick={(product) => onDecrementCountClick(product)}
              onIncrementCountClick={(product) => onIncrementCountClick(product)}
            />
            <div className={styles.clearBasket}>
              <Button onClick={clearBasketHandler} className={styles.clearBasketButton}>
                Clear basket
              </Button>
            </div>
          </>
        ) : (
          <SplashEmptyScreen />
        )}
      </Box>

      <RemoveProductDialog
        product={deletingProduct}
        onApply={() => onRemoveDialogApply()}
        onCloseClick={() => onRemoveDialogClose()}
        open={removeDialogOpen}
      />
      {inputDialogOpen && (
        <ManualInputDialog
          open={inputDialogOpen}
          onCloseClick={() => handleManualInputClose()}
          onApply={(barcode) => handleManualInputSubmit(barcode)}
        />
      )}

      {infoDialogOpen && (
        <InfoDialog open={infoDialogOpen} onCloseClick={() => handleInfoClose()} onApply={(barcode) => handleManualInputSubmit(barcode)} />
      )}
      <ShopList
        handleClose={() => handleCloseShopsPanel()}
        open={shopDialogOpen}
        handleChoose={(shop) => dispatch(ShopsActions.setCurrentShop(shop))}
      />
      <PurchaseConfirmDialog
        open={isConfirmCartDialogVisible}
        purchasePrice={totalBasketPrice}
        handleClose={handleCartDialogClose}
        handleConfirm={handleCartDialogConfirm}
      />
    </Box>
  );
};

export default Scanner;
