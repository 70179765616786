export const hasCameraAccess = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    return !(stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0);
  } catch (err) {
    if (err) {
      console.log('User has denied accessed');
    }
    return false;
  }
};
