import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import styles from './DevSupportDialog.module.scss';

interface Props {
  open: boolean;
  settings: DevSupportModel;
  onCloseClick: () => void;
  onSubmit: (data: DevSupportModel) => void;
}

interface DevSupportModel {
  calculateLocally: boolean;
  skipPayment: boolean;
}

const DevSupportDialog = (props: Props) => {
  const [calculationMode, setCalculationMode] = useState<{ calculateLocally: boolean }>({
    calculateLocally: props.settings.calculateLocally,
  });
  const [paymentMode, setPaymentMode] = useState<{ skipPayment: boolean }>({ skipPayment: props.settings.skipPayment });

  const onClose = () => {
    props.onCloseClick();
  };

  const onSubmit = () => {
    const result = { ...calculationMode, ...paymentMode };
    props.onSubmit(result);
    onClose();
  };

  const handleCalculationModeRadioChange = (data) => {
    setCalculationMode({ calculateLocally: data.target.value === 'local' });
  };

  const handlePaymentModeRadioChange = (data) => {
    setPaymentMode({ skipPayment: data.target.value === 'skip' });
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => onClose()}
      aria-labelledby="dev-support"
      aria-describedby="dev-support"
      fullWidth={true}
      className={styles.dialog}
    >
      <form noValidate>
        <DialogTitle id="dev-support-dialog" className={styles.title}>
          DevSupport
        </DialogTitle>
        <DialogContent>
          <FormLabel className={styles.subtitle}>Calculation Mode</FormLabel>
          <RadioGroup aria-labelledby="calculationMethod-label" name="calculationMethod" onChange={handleCalculationModeRadioChange}>
            <FormControlLabel
              value="local"
              className={styles.listItem}
              control={<Radio checked={calculationMode.calculateLocally === true} />}
              label="Local"
            />
            <FormControlLabel
              value="viking"
              className={styles.listItem}
              control={<Radio checked={calculationMode.calculateLocally === false} />}
              label="Viking"
            />
          </RadioGroup>
          <br />
          <FormLabel className={styles.subtitle}>Payment Method</FormLabel>
          <RadioGroup aria-labelledby="paymentMethod-label" name="paymentMethod" onChange={handlePaymentModeRadioChange}>
            <FormControlLabel
              value="skip"
              className={styles.listItem}
              control={<Radio checked={paymentMode.skipPayment === true} />}
              label="Skip"
            />
            <FormControlLabel
              value="netaxept"
              className={styles.listItem}
              control={<Radio checked={paymentMode.skipPayment === false} />}
              label="NetAxept"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={() => onClose()} className={styles.actionButton}>
            Cancel
          </Button>
          <Button autoFocus onClick={onSubmit} className={styles.actionButton}>
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DevSupportDialog;
