import { filter, ignoreElements, map, mergeMap, of, withLatestFrom, timer, debounce } from 'rxjs';
import { AppEpic } from '../../../../app/store';
import { BasketService } from '../../../../services';
import { LayoutActions, LayoutSnackbarActions } from '../../../layout/store/actions';
import { BarcodeActions, ShopsActions } from '../actions';
import { SystemHelpers } from '../../../../app/helpers';

export const postBarcodeEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(BarcodeActions.postProducts.match),
    withLatestFrom(state$),
    mergeMap(([{ payload: products }, state]) =>
      state.devSupport.calculateLocally
        ? BasketService.calculateBasketLocally(products, state.shops.currentShop).pipe(
            mergeMap((response) =>
              response.data.errorsArr && response.data.errorsArr.length > 0
                ? of(
                    BarcodeActions.barcodeUnknown(),
                    BarcodeActions.barcodeFulfilled(response.data),
                    BarcodeActions.saveTempReceiptBarcode(response.data.tempReceiptBarCode),
                    LayoutActions.setCalculationInProgress(false)
                  )
                : of(
                    BarcodeActions.barcodeFulfilled(response.data),
                    BarcodeActions.saveTempReceiptBarcode(response.data.tempReceiptBarCode),
                    LayoutActions.setCalculationInProgress(false)
                  )
            )
          )
        : BasketService.basketPost(products, state.shops.currentShop).pipe(
            mergeMap((response) =>
              response.data.errorsArr && response.data.errorsArr.length > 0
                ? of(
                    BarcodeActions.barcodeUnknown(),
                    BarcodeActions.barcodeFulfilled(response.data),
                    BarcodeActions.saveTempReceiptBarcode(response.data.tempReceiptBarCode),
                    LayoutActions.setCalculationInProgress(false)
                  )
                : of(
                    BarcodeActions.barcodeFulfilled(response.data),
                    BarcodeActions.saveTempReceiptBarcode(response.data.tempReceiptBarCode),
                    LayoutActions.setCalculationInProgress(false)
                  )
            )
          )
    )
  );

export const barcodeUnknownEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(BarcodeActions.barcodeUnknown.match),
    map(() => LayoutSnackbarActions.showSnackbar('Unknown item'))
  );

export const barcodeIncrementEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(BarcodeActions.barcodeIncrementCount.match),
    map(({ payload }) => BarcodeActions.barcodeRepeated(payload))
  );

export const barcodePostEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(
      (action) =>
        BarcodeActions.putBarcode.match(action) ||
        BarcodeActions.barcodeRepeated.match(action) ||
        BarcodeActions.barcodeDecrementCount.match(action) ||
        BarcodeActions.barcodeRemove.match(action)
    ),
    withLatestFrom(state$),
    debounce(() => timer(1000)),
    mergeMap(([action, state]) => {
      if (!state.shops.currentShop) {
        return [ShopsActions.getSupportedShops()];
      }

      if (action.type !== BarcodeActions.barcodeRemove.type) {
        return [BarcodeActions.postProducts(state.scanner.items)];
      } else if (state.scanner.items.length === 0) {
        return [BarcodeActions.clearBasket(), LayoutActions.setCalculationInProgress(false)];
      } else {
        return [BarcodeActions.postProducts(state.scanner.items)];
      }
    })
  );

export const rejectPostBarcodeEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(BarcodeActions.rejectPostBarcode.match),
    map(() => ShopsActions.closeShopsPanel())
  );

export const tempReceiptBarCodeEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(BarcodeActions.saveTempReceiptBarcode.match),
    map(({ payload: tempReceiptBarcode }) => SystemHelpers.saveTempReceiptBarcode(tempReceiptBarcode)),
    ignoreElements()
  );
