import styles from './CameraAccessScreen.module.scss';
import { SplashPermissionTips } from '../permission-tips/SplashPermissionTips';
import SagButton from '../../../components/sag-button/SagButton';
import React, { useState } from 'react';
import { Logo } from './Logo';

const CameraAccessScreen: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.splashContainer}>
      <Logo height="150px" width="150px" />
      <div className={styles.title}>To scan items, please allow access to your location and camera.</div>
      <SagButton value="Need help ?" className={styles.accessButton} onClick={handleOpen} />
      {open && <SplashPermissionTips onClose={handleClose} />}
    </div>
  );
};

export default CameraAccessScreen;
