import styles from './Header.module.scss';
import React, { CSSProperties, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import DevSupportDialog from '../../dev-support/dev-support-dialog/DevSupportDialog';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DevSupportActions } from '../../dev-support/store/actions';
import { DevSupportSelectors } from '../../dev-support/store/selectors';
import { BarcodeActions } from '../../scanner/store/actions';
import { Logo } from './Logo';

interface Props {
  pos: 'relative' | 'fixed';
  title?: string;
  style?: CSSProperties;
}

const Header: React.FC<Props> = (props: Props) => {
  const headerStyle: CSSProperties = {
    position: props.pos,
  };

  const [showModal, setShowModal] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseDown = () => {
    timeoutRef.current = setTimeout(() => {
      setShowModal(true);
    }, 2000);
  };

  const handleMouseUp = () => {
    clearTimeout(timeoutRef.current!);
  };

  const dispatch = useAppDispatch();
  const currentDevSupportSettings = useAppSelector(DevSupportSelectors.getDevSupport);

  const handleDevSupportChange = (data) => {
    dispatch(DevSupportActions.setDevSupportSettings(data));

    // Clear basket if calculation mode is changed
    const calculationModeChanged = data.calculateLocally !== currentDevSupportSettings.calculateLocally;
    if (calculationModeChanged) {
      dispatch(BarcodeActions.clearBasket());
    }
  };

  return (
    <Box className={styles.header} style={{ ...headerStyle, ...props.style }}>
      <Box className={styles.imageWrapper}>
        <Logo height="30px" width="30px" />
      </Box>
      <Box className={styles.title}>{props.title}</Box>
      <Box>
        <Button
          className={styles.devSupport}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          DevSupport
        </Button>
        {showModal && (
          <DevSupportDialog
            open={showModal}
            settings={currentDevSupportSettings}
            onCloseClick={() => setShowModal(false)}
            onSubmit={(data) => handleDevSupportChange(data)}
          />
        )}
      </Box>
    </Box>
  );
};

export default Header;
