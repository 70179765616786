import { createAction, createReducer, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

//Write your reducers here

export const testAddAction = createAction<number>('testAddAction');

const testReducer = createReducer(0, {
  [testAddAction.type]: (state, action: PayloadAction<number>) => state + action.payload,
});

const testStateSelector = (state: RootState) => state.count;

export const getCount = createSelector(testStateSelector, (state) => state);

export default testReducer;
