import { from } from 'rxjs';
import httpClient from '../app/http-clients/http-client';
import { Product } from '../app/models/Product';
import { Shop } from '../app/models/shop/Shop';
import { Basket } from '../app/models/Basket';
import httpPaymentClient from '../app/http-clients/http-payment-client';
import { SystemHelpers } from '../app/helpers';
import { ConfirmPaymentPayload } from '../features/payment/store/models/payment.models';

const axiosTempConfig = {
  headers: {
    'x-tenant-alias': 'TID-PQHE4X',
  },
};

export const basketPost = (products: Product[], store: Shop | undefined) =>
  from(
    httpClient.post(
      '/basket',
      {
        clientTraceId: 'scanGoWeb',
        storeId: store ? store.storeId : '00000000-0000-0000-0000-000000007585',
        items: [...products],
      },
      axiosTempConfig
    )
  );

export const calculateBasketLocally = (products: Product[], store: Shop | undefined) =>
  from(
    httpClient.post(
      '/calculateLocally',
      {
        clientTraceId: 'scanGoWeb',
        storeId: store ? store.storeId : '00000000-0000-0000-0000-000000007585',
        items: [...products],
      },
      axiosTempConfig
    )
  );

export const confirmPaymentLocally = (basket: Basket) =>
  from(
    httpClient.post(
      '/payment/confirmPaymentLocally',
      {
        id: basket.id,
      },
      axiosTempConfig
    )
  );

export const supportedShopsGet = () => from(httpClient.get('/store/supportedStores'));

function getRedirectUrl() {
  return `${window.location.origin}/payment-successful`;
}

export const paymentStartPost = (basket: Basket) => {
  const payload = {
    storeId: basket.storeId,
    basketId: basket.lastBasketEventId,
    paymentRefId: basket.paymentRefId,
    amount: basket.totalBasketPrice,
    currency: 'DKK',
    paymentMeans: 'NETAXEPT',
    clientTraceId: basket.clientTraceId,
    lang: 'en',
    redirectUrl: getRedirectUrl(),
    sendReceipt: true,
  };
  return from(
    httpPaymentClient.post('/payment/webSession', payload, {
      headers: {
        'X-Authorization-Device': 'Bearer ' + SystemHelpers.getXAuthorizationDevice(),
        'X-Request-Signature': 'Bearer ' + SystemHelpers.generateJwtPayloadToken(payload),
      },
    })
  );
};

export const paymentConfirmPost = (payload: ConfirmPaymentPayload) =>
  from(
    httpPaymentClient.post('payment/confirmWebPayment', payload, {
      headers: {
        'X-Authorization-Device': 'Bearer ' + SystemHelpers.getXAuthorizationDevice(),
        'X-Request-Signature': 'Bearer ' + SystemHelpers.generateJwtPayloadToken(payload),
      },
    })
  );

export const paymentStatusGet = (paymentRefId: string) => from(httpPaymentClient.get(`payment/${paymentRefId}/3dsStatus`));
