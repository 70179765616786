import './App.scss';
import Layout from './features/layout/Layout';
import { Route, Switch } from 'react-router';
import Scanner from './features/scanner/Scanner';
import { Cards } from './features/cards/Cards';
import { NetAxept } from './features/cards/net-axept/NetAxept';
import { Receipts } from './features/receipts/Receipts';
import { ReceiptView } from './features/receipts/receipt-view/ReceiptView';
import { News } from './features/news/News';
import { useEffect } from 'react';
import { LayoutActions } from './features/layout/store/actions';
import { useAppDispatch } from './app/hooks';
import { PaymentProcessed } from './features/payment/payment-processed/PaymentProcessed';
import { PaymentApproved } from './features/payment/payment-approved/PaymentApproved';
import { PaymentFinished } from './features/payment/payment-finished/PaymentFinished';
import React from 'react';

interface PageProps {
  component: unknown;
  title?: string;
  isFooterHidden?: boolean;
}

const Page = (props: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(LayoutActions.setHeaderTitle(props.title ? props.title : ''));
    dispatch(LayoutActions.setFooterHidden(!!props.isFooterHidden));
  }, [dispatch, props.title, props.isFooterHidden]);

  const RenderedComponent = props.component;
  // @ts-ignore
  return <RenderedComponent />;
};

const App = () => {
  return (
    <div className="app">
      <Layout>
        <Switch>
          <Route exact path="/" render={() => <Page component={Scanner} />} />
          <Route path="/cards" render={() => <Page component={Cards} title="Saved cards" />} />
          <Route path="/test" render={() => <div style={{ top: '50vh' }}>Hello</div>} />
          <Route path="/netaxept" component={NetAxept} />
          <Route
            path="/receipts/:receiptId"
            render={() => <Page component={ReceiptView} title="Receipt details" isFooterHidden={true} />}
          />
          <Route path="/receipts" render={() => <Page component={Receipts} title="Receipts" />} />
          <Route path="/news" render={() => <Page component={News} title="News" />} />
          <Route path="/payment-successful" render={() => <Page component={PaymentProcessed} isFooterHidden={true} />} />
          <Route path="/payment-approved" render={() => <Page component={PaymentApproved} isFooterHidden={true} />} />
          <Route path="/payment-finished" render={() => <Page component={PaymentFinished} isFooterHidden={true} />} />
        </Switch>
      </Layout>
    </div>
  );
};

export default App;
