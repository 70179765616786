import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs';
import { BarcodeEpics, ShopsEpics } from '../features/scanner/store/epics';
import { AppEpic } from './store';
import { LayoutSideBarEpics } from '../features/layout/store/epics';
import { CardsEpics } from '../features/cards/store/epics';
import { ReceiptsEpics } from '../features/receipts/store/epics';
import { NewsEpics } from '../features/news/store/epics';
import { PaymentEpics } from '../features/payment/store/epics';

const epics = combineEpics(
  LayoutSideBarEpics.sideBarCloseEpic,
  BarcodeEpics.postBarcodeEpic,
  BarcodeEpics.barcodeUnknownEpic,
  BarcodeEpics.barcodeIncrementEpic,
  BarcodeEpics.barcodePostEpic,
  ShopsEpics.setCurrentShopEpic,
  ShopsEpics.supportedShopsEpic,
  BarcodeEpics.rejectPostBarcodeEpic,
  BarcodeEpics.tempReceiptBarCodeEpic,
  CardsEpics.creatingUrlEpic,
  CardsEpics.createCardEpic,
  CardsEpics.loadSavedCards,
  CardsEpics.cachePrimaryCard,
  ReceiptsEpics.receiptsGetEpic,
  NewsEpics.loadNewsEpic,
  PaymentEpics.loadPaymentSessionUrlEpic,
  PaymentEpics.confirmPaymentEpic,
  PaymentEpics.checkPaymentStatus,
  PaymentEpics.QRCodeTimerEpic,
  PaymentEpics.QRCodeTimerCountdownEpic,
  PaymentEpics.skipPaymentEpic
);

const rootEpic: AppEpic = (action$, store$, dependencies) =>
  epics(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export default rootEpic;
