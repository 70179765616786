import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

const shopSelector = (state: RootState) => state.shops;

export const getSupportedShops = createSelector(shopSelector, (state) => state.supportedShops);

export const getCurrentShop = createSelector(shopSelector, (state) => state.currentShop);

export const shopsPanelOpen = createSelector(shopSelector, (state) => state.shopsPanelOpen);

export const shopsFilter = (searchQuery: string = '') =>
  createSelector(shopSelector, (state) => {
    return state.supportedShops.filter(
      (shop) =>
        shop.address.city?.toLowerCase().includes(searchQuery) ||
        shop.address.country?.toLowerCase().includes(searchQuery) ||
        shop.address.street?.toLowerCase().includes(searchQuery) ||
        shop.address.zip?.toLowerCase().includes(searchQuery) ||
        shop.name?.toLowerCase().includes(searchQuery)
    );
  });
