import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import styles from './NetAxept.module.scss';
import { CircularProgress } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { CardsActions } from '../store/actions';
import { CardsSelectors } from '../store/selectors';
import React from 'react';

export const NetAxept: React.FC = () => {
  const responseCode = useAppSelector((state) => state.router.location?.query?.responseCode);
  const transactionId = useAppSelector((state) => state.router.location?.query?.transactionId);
  const spinnerShow = useAppSelector(CardsSelectors.creationSpinnerShow);
  const dispatch = useAppDispatch();

  if (responseCode === 'OK') {
    dispatch(CardsActions.createCard(transactionId));
  }

  return (
    <div className={styles.netAxeptContainer}>
      {spinnerShow ? (
        <CircularProgress className={styles.content} />
      ) : (
        <div className={styles.cardLinked}>
          <CheckCircleOutlineRoundedIcon className={styles.checkIcon} sx={{ fontSize: '72px' }} />
          <div className={styles.title}>
            Card linked <br /> successfully
          </div>
        </div>
      )}
    </div>
  );
};
