import { RootState } from '../../../../app/store';
import { createSelector } from '@reduxjs/toolkit';

const cardsSelector = (state: RootState) => state.cards;

export const creatingUrl = createSelector(cardsSelector, (state) => state.urlCreating);

export const creationSpinnerShow = createSelector(cardsSelector, (state) => state.creationSpinnerShow);

export const savedCards = createSelector(cardsSelector, (state) => state.savedCards);

export const primaryCardId = createSelector(cardsSelector, (state) => state.primaryCardId);
