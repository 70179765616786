import { createReducer } from '@reduxjs/toolkit';
import { LayoutActions } from '../actions';

interface State {
  footerTitle: string;
  footerSubTitle: string;
  footerHidden: boolean;
  headerTitle: string;
  footerCartButtonEnabled: boolean;
  isConfirmCartDialogVisible: boolean;
  calculationInProgress: boolean;
  showSpinner: boolean;
}

const initialState: State = {
  footerTitle: '',
  footerSubTitle: '',
  headerTitle: '',
  footerCartButtonEnabled: false,
  isConfirmCartDialogVisible: false,
  footerHidden: false,
  calculationInProgress: false,
  showSpinner: false,
};

export const layoutReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(LayoutActions.setFooterTitle, (state, { payload: text }) => ({
      ...state,
      footerTitle: text,
    }))
    .addCase(LayoutActions.setFooterSubTitle, (state, { payload: text }) => ({
      ...state,
      footerSubTitle: text,
    }))
    .addCase(LayoutActions.setHeaderTitle, (state, { payload: text }) => ({
      ...state,
      headerTitle: text,
    }))
    .addCase(LayoutActions.setFooterCartButtonEnabled, (state, { payload: value }) => ({
      ...state,
      footerCartButtonEnabled: value,
    }))
    .addCase(LayoutActions.showConfirmCartDialog, (state) => ({
      ...state,
      isConfirmCartDialogVisible: true,
    }))
    .addCase(LayoutActions.hideConfirmCartDialog, (state) => ({
      ...state,
      isConfirmCartDialogVisible: false,
    }))
    .addCase(LayoutActions.setFooterHidden, (state, { payload: isHidden }) => ({
      ...state,
      footerHidden: isHidden,
    }))
    .addCase(LayoutActions.setCalculationInProgress, (state, { payload: isCalculationInProgress }) => ({
      ...state,
      calculationInProgress: isCalculationInProgress,
    }))
    .addCase(LayoutActions.showSpinner, (state, { payload: showSpinner }) => ({
      ...state,
      showSpinner,
    }))
);
