import styles from './ReceiptsList.module.scss';
import { Receipt } from '../models/receipts.models';
import { ReceiptsHelpers } from '../../../app/helpers';
import React from 'react';

interface Props {
  receipts: Receipt[];
  onReceiptClick?: (receipt: Receipt) => void;
}

export const ReceiptsList: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.receiptsListPaper}>
      {props.receipts.map((receipt) => (
        <div className={styles.listContainer} key={receipt.id} onClick={() => (props.onReceiptClick ? props.onReceiptClick(receipt) : {})}>
          <div className={styles.receipt}>
            <div className={styles.receiptInfo}>
              <span>{ReceiptsHelpers.createdAtDateFilter(receipt.createdAt)}</span>
              <span>
                {receipt.storeInfo.storeName} {receipt.storeInfo.zip}, {receipt.storeInfo.address}
              </span>
            </div>
            <hr />
            <div className={styles.receiptPrice}>
              <div className={styles.receiptPriceTitle}>Total:</div>
              <div className={styles.receiptPriceNumber}>{ReceiptsHelpers.priceFormat(receipt.totalBasketPrice)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
