import { createReducer } from '@reduxjs/toolkit';
import { SideBarItemModel } from '../../models/SideBarItemModel';
import homeSvg from '../../../../assets/sidebar/home.svg';
import cardSvg from '../../../../assets/sidebar/card.svg';
import receiptSvg from '../../../../assets/sidebar/receipt.svg';
import profileSvg from '../../../../assets/sidebar/profile.svg';
import newsSvg from '../../../../assets/sidebar/news.svg';
import condSvg from '../../../../assets/sidebar/condition.svg';
import feedbackSvg from '../../../../assets/sidebar/feedback.svg';
import rateSvg from '../../../../assets/sidebar/rate.svg';
import arrowSvg from '../../../../assets/sidebar/arrow.svg';
import launchSvg from '../../../../assets/sidebar/launch.svg';
import { featureKey } from '../../index';
import { SidebarState } from '../../models/SideBarState';

export const sideBarItems: SideBarItemModel[] = [
  {
    icon: homeSvg,
    title: 'Home',
    rightSideIcon: arrowSvg,
    route: '/',
  },
  {
    icon: cardSvg,
    title: 'Payment card',
    rightSideIcon: arrowSvg,
    route: '/cards',
  },
  {
    icon: receiptSvg,
    title: 'Receipts',
    rightSideIcon: arrowSvg,
    route: '/receipts',
  },
  {
    icon: profileSvg,
    title: 'Profile settings',
    rightSideIcon: arrowSvg,
  },
  {
    icon: newsSvg,
    title: 'News',
    rightSideIcon: arrowSvg,
    route: '/news',
  },
  {
    icon: condSvg,
    title: 'Conditions',
    rightSideIcon: arrowSvg,
  },
  {
    icon: feedbackSvg,
    title: 'Leave feedback',
    rightSideIcon: launchSvg,
  },
  {
    icon: rateSvg,
    title: 'Rate the app',
    rightSideIcon: launchSvg,
  },
];

const initialState: SidebarState = {
  items: sideBarItems,
  isOpen: false,
};

export const sidebarReducer = createReducer(initialState, {
  [featureKey + '/openSideBar']: (state) => {
    return {
      ...state,
      isOpen: true,
    };
  },
  [featureKey + '/closeSideBar']: (state) => {
    return {
      ...state,
      isOpen: false,
    };
  },
});
