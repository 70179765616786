import { News } from '../models/News';
import styles from './NewsList.module.scss';
import { NewsHelpers } from '../../../app/helpers';
import { useState } from 'react';
import { NewsDetail } from '../news-detail/NewsDetail';
import Linkify from 'react-linkify';
import React from 'react';

interface Props {
  news: News[];
  onItemDetailsClick: (newsItem: News) => void;
}

export const NewsList: React.FC<Props> = (props: Props) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [detailedItem, setDetailedItem] = useState<News | undefined>(undefined);

  const handleDetailsOpen = (newsItem: News) => {
    setDetailsOpen(true);
    setDetailedItem(newsItem);
    NewsHelpers.markRead(newsItem.id);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };

  return (
    <div className={styles.newsList}>
      {props.news.map((newsItem) => (
        <div
          className={styles.newsItem}
          key={newsItem.id}
          onClick={() => {
            handleDetailsOpen(newsItem);
            props.onItemDetailsClick(newsItem);
          }}
        >
          {!NewsHelpers.isReadNews(newsItem.id) && <div className={styles.readBox}>UNREAD</div>}
          <div className={styles.newsItemHeader}>
            <div className={styles.newsItemDate}>{NewsHelpers.createdAtDateFilter(newsItem.createdAt)}</div>
            <div className={styles.newsItemTitle}>{newsItem.textContent.title.en}</div>
          </div>
          <div className={styles.newsItemContent}>
            <div className={styles.newsItemMessage}>
              <Linkify>{NewsHelpers.getTextOverflowed(newsItem.textContent.message.en)}</Linkify>
            </div>
            <a className={styles.newsItemMoreBtn} href="/" onClick={(e) => e.preventDefault()}>
              Read more
            </a>
          </div>
        </div>
      ))}
      <NewsDetail open={detailsOpen} newsItem={detailedItem} onCloseClick={handleDetailsClose} />
    </div>
  );
};
