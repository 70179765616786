import { createReducer } from '@reduxjs/toolkit';
import { DevSupportActions } from '../actions';

const LOCAL_STORAGE_ID = 'devSupport';

interface State {
  calculateLocally: boolean;
  skipPayment: boolean;
}

const getInitialState = (): State => {
  try {
    const devSupport = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)!);
    if (devSupport) {
      return devSupport;
    }
  } catch (e) {}

  return {
    calculateLocally: true,
    skipPayment: true,
  };
};

const initialState = getInitialState();

export const devSupportReducer = createReducer(initialState, (builder) =>
  builder.addCase(DevSupportActions.setDevSupportSettings, (state, { payload: settings }) => {
    localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify(settings));
    return {
      ...state,
      calculateLocally: settings.calculateLocally,
      skipPayment: settings.skipPayment,
    };
  })
);
