import { createAction } from '@reduxjs/toolkit';
import { featureKey } from '../../../layout';
import { Product } from '../../../../app/models/Product';
import { Basket } from '../../../../app/models/Basket';

export const putBarcode = createAction<string>(featureKey + '/putBarcode');

export const postProducts = createAction<Product[]>(featureKey + '/postBarcode');

export const barcodeFulfilled = createAction<Basket>(featureKey + '/barcodeFulfilled');

export const barcodeUnknown = createAction(featureKey + '/barcodeUnknown');

export const barcodeRepeated = createAction<Product>(featureKey + '/barcodeRepeated');

export const barcodeIncrementCount = createAction<Product>(featureKey + '/barcodeIncrementCount');

export const barcodeDecrementCount = createAction<Product>(featureKey + '/barcodeDecrementCount');

export const barcodeRemove = createAction<Product>(featureKey + '/barcodeRemove');

export const clearBasket = createAction(featureKey + '/clearBasket');

export const rejectPostBarcode = createAction(featureKey + '/rejectPostBarcode');

export const saveTempReceiptBarcode = createAction<string>(featureKey + '/saveTempReceiptBarcode');
