import styles from './ReceiptView.module.scss';
import { ReceiptsHelpers, SystemHelpers } from '../../../app/helpers';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReceiptsSelectors } from '../store/selectors';
import React, { useEffect } from 'react';
import { ReceiptsActions } from '../store/actions';
import { Box, Divider, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { goBack } from 'connected-react-router';

export const ReceiptView: React.FC = () => {
  // @ts-ignore
  const { receiptId } = useParams();
  const dispatch = useAppDispatch();
  const receipt = useAppSelector(ReceiptsSelectors.getReceiptById(receiptId));

  useEffect(() => {
    if (!receipt) setTimeout(() => dispatch(ReceiptsActions.loadReceipts()), 2000);
  });

  useEffect(() => {
    console.log(receipt);
  }, [receipt]);

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };

  return (
    <Box className={styles.viewContainer}>
      <Box className={styles.status}>
        <Box className={styles.placeholder} />
        <Box className={styles.statusText}>Receipt Details</Box>
        <Box component="a" href="/" onClick={handleClose} className={styles.closeButton}>
          <CloseIcon />
        </Box>
      </Box>
      <Box className={styles.receipt}>
        <Box className={styles.receiptInfo}>
          <Box component="span">{receipt ? ReceiptsHelpers.createdAtDateFilter(receipt.createdAt) : <Skeleton />}</Box>
          <Box component="span">
            {receipt ? `${receipt.storeInfo.storeName} ${receipt.storeInfo.zip}, ${receipt.storeInfo.address}` : <Skeleton />}
          </Box>
        </Box>
        <Box className={styles.receiptItems}>
          {receipt ? (
            receipt.items.map((item) => (
              <Box key={item.ean}>
                <Divider />
                <Box>
                  <Box className={styles.listItem}>
                    <Box className={styles.itemName}>{item.description}</Box>
                    <Box className={styles.itemCount}>{item.quantity} pcs</Box>
                    <Box
                      className={`${styles.itemPrice} ${item && item.related && item.related.length > 0 ? styles.itemPricePantFormat : ''}`}
                    >
                      <Box>{ReceiptsHelpers.priceFormat(item.totalPrice!)}</Box>
                      <Box component="sub" className={styles.itemPricePant}>
                        {item && item.related && item.related.length > 0
                          ? `+pant ${ReceiptsHelpers.priceFormat(item.related[0].totalPrice)}`
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>
            ))
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
        </Box>
        <Box className={styles.receiptPrice}>
          <Box className={styles.receiptPriceTotal}>
            <Box>Total:</Box>
            <Box>{receipt ? ReceiptsHelpers.priceFormat(receipt.totalBasketPrice) : <Skeleton />}</Box>
          </Box>
          <Box>
            <Box>Included deposit:</Box>
            <Box>{receipt ? ReceiptsHelpers.priceFormat(SystemHelpers.summarizeProductsPants(receipt.items)) : <Skeleton />}</Box>
          </Box>
          <Box>
            <Box>Included VAT:</Box>
            <Box>{receipt ? ReceiptsHelpers.priceFormat(receipt.tax) : <Skeleton />}</Box>
          </Box>
          <Divider className={styles.divider} />
        </Box>
        <Box className={styles.transactionInfo}>
          <Box className={styles.transactionInfoTitle}>Transaction details:</Box>
          <Box>
            <Box>Payment ref: {receipt ? receipt.paymentRefId : <Skeleton />}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
