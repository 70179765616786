import Header from '../header/Header';
import { SideBarItemModel } from '../models/SideBarItemModel';
import { SideBarItem } from '../sidebar-item/SideBarItem';
import PersonIcon from '@mui/icons-material/Person';
import './SideBarContent.scss';
import { Shop } from '../../../app/models/shop/Shop';
import React from 'react';

interface Props {
  items: SideBarItemModel[];
  onShopClick?: () => void;
  onItemClick?: (item: SideBarItemModel) => void;
  currentShop?: Shop;
  className?: string;
}

const SideBarContent: React.FC<Props> = (props: Props) => {
  const handleOnShopClick = (e) => {
    e.preventDefault();
    if (props.onShopClick) {
      props.onShopClick();
    }
  };

  return (
    <div className={`sidebar ${props.className}`}>
      <div className="sidebar-header-container">
        <Header pos="relative" style={{ paddingLeft: '14px' }} />
        <div className="sidebar-subheader">
          <div className="sidebar-subheader-title">MY PROFILE</div>
          <div className="sidebar-subheader-body">
            <div>t-user+20200831T10</div>
            <div>Version: 2.6.1012.</div>
            {props.currentShop && (
              <div className="sidebar-subheader-shop">
                <a style={{ color: '#5B84BA' }} href="/" onClick={(e) => handleOnShopClick(e)}>
                  {props.currentShop.name}
                </a>
                <PersonIcon />
              </div>
            )}
          </div>
          <div className="sidebar-subheader-logout">
            <a href="/">Logout</a>
          </div>
        </div>
      </div>
      <div className="sidebar-items">
        {props.items.map((p) => (
          <SideBarItem key={p.title} sidebarItem={p} onClick={(e) => (props.onItemClick ? props.onItemClick(e) : {})} />
        ))}
      </div>
    </div>
  );
};
export default SideBarContent;
