import { createReducer } from '@reduxjs/toolkit';
import { CardsActions } from '../actions';
import { Card } from '../../../../app/models/payment/Card';
import { CardsHelpers } from '../../../../app/helpers';

interface State {
  savedCards: Card[];
  urlCreating: string;
  creationSpinnerShow: boolean;
  primaryCardId: string;
}

const initState: State = {
  savedCards: [],
  urlCreating: '',
  creationSpinnerShow: true,
  primaryCardId: CardsHelpers.getPrimaryCardIdFromCache(),
};

export const cardsReducer = createReducer(initState, (builder) =>
  builder
    .addCase(CardsActions.urlCreateCardFulfilled, (state, { payload: creatingUrl }) => ({
      ...state,
      urlCreating: creatingUrl,
    }))
    .addCase(CardsActions.createCard, (state) => ({
      ...state,
      creationSpinnerShow: true,
    }))
    .addCase(CardsActions.createCardFulfilled, (state, { payload: card }) => ({
      ...state,
      savedCards: [...state.savedCards, card],
      creationSpinnerShow: false,
    }))
    .addCase(CardsActions.savedCardsFulfilled, (state, { payload: cards }) => ({
      ...state,
      savedCards: cards,
    }))
    .addCase(CardsActions.makeCardPrimary, (state, { payload: card }) => ({
      ...state,
      primaryCardId: card.id,
    }))
);
