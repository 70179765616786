import { v4 as generateUUID } from 'uuid';
import jwt from 'jsonwebtoken';
import { DateTime } from 'luxon';
import { Product } from '../models/Product';

const DEVICE_ID_KEY = 'device-id';
const DEVICE_KEY = 'device-key';
const USER_ID = 'user-id';
const TEMP_RECEIPT_BARCODE_KEY = 'temp-receipt-barcode';

export const generateAndGetDeviceId = (): string => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (deviceId) return deviceId;
  deviceId = generateUUID() as string;
  localStorage.setItem(DEVICE_ID_KEY, deviceId);
  return deviceId;
};

export const generateAndGetDeviceKey = (): string => {
  let deviceKey = localStorage.getItem(DEVICE_KEY);
  if (deviceKey) return deviceKey;
  deviceKey = btoa(generateUUID());
  localStorage.setItem(DEVICE_KEY, deviceKey);
  return deviceKey;
};

export const isDeviceCredentialsGenerated = (): boolean => {
  let deviceKey = localStorage.getItem(DEVICE_KEY);
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);

  return !!deviceId && !!deviceKey;
};

export const getXAuthorizationDevice = (): string => {
  return jwt.sign(
    {
      iss: 'scan-and-go',
      sub: generateAndGetDeviceId(),
      aud: 'sgpayment',
      iat: DateTime.now().toJSDate().getTime(),
    },
    generateAndGetDeviceKey(),
    { algorithm: 'HS512', expiresIn: 3 * 60 }
  );
};

export const generateJwtPayloadToken = (payload): string => {
  return jwt.sign({ payload: payload }, generateAndGetDeviceKey(), { algorithm: 'HS512', noTimestamp: true });
};

export const cacheUserId = (userId: string) => {
  localStorage.setItem(USER_ID, userId);
};

export const getCachedUserId = (): string => {
  const userId = localStorage.getItem(USER_ID);
  if (!userId) return '';
  return userId;
};

export const saveTempReceiptBarcode = (value: string) => {
  localStorage.setItem(TEMP_RECEIPT_BARCODE_KEY, value);
};

export const getTempReceiptBarcode = () => {
  return localStorage.getItem(TEMP_RECEIPT_BARCODE_KEY);
};

export const summarizeProductsPants = (products: Product[]) => {
  return products.reduce((sum, value) => {
    if (value && value.related && value.related.length > 0) return sum + value.related[0].totalPrice;
    return sum;
  }, 0);
};
