import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

const paymentSelector = (state: RootState) => state.payment;

export const getSessionUrl = createSelector(paymentSelector, (state) => state.sessionUrl);

export const getPaymentStatus = createSelector(paymentSelector, (state) => state.currentPaymentStatus);

export const getPaymentFinishCountdown = createSelector(paymentSelector, (state) => state.paymentFinishCountdown);
