import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RootState } from '../../../../app/store';
import { NewsHelpers } from '../../../../app/helpers';

const newsSelector = (state: RootState) => state.news;

export const getNews = createSelector(newsSelector, (state) => state.news);

export const getSortedNews = createSelector(newsSelector, (state) => {
  const readNews = state.news
    .filter((news) => NewsHelpers.isReadNews(news.id))
    .sort((a, b) => DateTime.fromISO(b.createdAt).toJSDate().getTime() - DateTime.fromISO(a.createdAt).toJSDate().getTime());

  const unreadNews = state.news
    .filter((news) => !NewsHelpers.isReadNews(news.id))
    .sort((a, b) => DateTime.fromISO(b.createdAt).toJSDate().getTime() - DateTime.fromISO(a.createdAt).toJSDate().getTime());

  return [...unreadNews, ...readNews];
});

export const getUnreadNewsCount = createSelector(newsSelector, (state) => state.unreadNewsCount);
