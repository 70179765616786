import { createReducer } from '@reduxjs/toolkit';
import { LayoutSnackbarActions } from '../actions';

interface State {
  isShown: boolean;
  currentMessage: string;
}

const initState: State = {
  isShown: false,
  currentMessage: '',
};

export const snackbarReducer = createReducer(initState, (builder) =>
  builder
    .addCase(LayoutSnackbarActions.showSnackbar, (_, { payload: currentMessage }) => ({ isShown: true, currentMessage }))
    .addCase(LayoutSnackbarActions.hideSnackbar, () => ({ isShown: false, currentMessage: '' }))
);
