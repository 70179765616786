import { DateTime } from 'luxon';
import { News } from '../../features/news/models/News';

const READ_NEWS_KEY = 'read-news';

export const createdAtDateFilter = (date: string) => {
  return DateTime.fromISO(date).toFormat('dd.MM.yyyy - HH:mm');
};

export const markRead = (newsId: string) => {
  let readNewsStr = localStorage.getItem(READ_NEWS_KEY);
  console.log('+++++++++++++++++++');
  let readNews;
  if (!readNewsStr) {
    readNews = [newsId];
  } else {
    readNews = JSON.parse(readNewsStr);
    readNews.push(newsId);
  }
  localStorage.setItem(READ_NEWS_KEY, JSON.stringify(readNews));
};

export const isReadNews = (newsId: string): boolean => {
  let readNewsStr = localStorage.getItem(READ_NEWS_KEY);
  let readNews;
  if (!readNewsStr) {
    return false;
  } else {
    readNews = JSON.parse(readNewsStr);
    return !!readNews.find((id) => newsId === id);
  }
};

export const countUnreadNews = (news: News[]): number => {
  if (news) return news.filter((p) => !isReadNews(p.id)).length;
  return 0;
};

export const getTextOverflowed = (text: string): string => {
  return text.slice(0, 160) + '...';
};
