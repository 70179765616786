import styles from './Cards.module.scss';
import { EditCardList } from './EditCardList/EditCardList';
import SagButton from '../../components/sag-button/SagButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CardsActions } from './store/actions';
import { CardsSelectors } from './store/selectors';
import React, { useEffect } from 'react';
import { Card } from '../../app/models/payment/Card';

export const Cards: React.FC = () => {
  const creatingUrl = useAppSelector(CardsSelectors.creatingUrl);
  const savedCards = useAppSelector(CardsSelectors.savedCards);
  const primaryCardId = useAppSelector(CardsSelectors.primaryCardId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (creatingUrl) window.location.href = creatingUrl;
  }, [creatingUrl]);

  useEffect(() => {
    if (!savedCards || savedCards.length <= 0) dispatch(CardsActions.loadSavedCards());
  });

  const handleMakePrimaryClick = (card: Card) => {
    dispatch(CardsActions.makeCardPrimary(card));
  };

  const handleDeleteCardClick = () => {
    alert('This feature will be implemented soon!');
  };

  const handleAddCardClick = () => {
    dispatch(CardsActions.getCreateCardUrl());
  };

  return (
    <div className={styles.cardsContainer}>
      <EditCardList
        cards={savedCards}
        primaryCardId={primaryCardId}
        onDeleteActionClick={handleDeleteCardClick}
        onPrimaryActionClick={handleMakePrimaryClick}
      />
      <SagButton value="Add card" className={styles.addCardButton} onClick={() => handleAddCardClick()} />
    </div>
  );
};
