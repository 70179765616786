import { NewsList } from './news-list/NewsList';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NewsSelectors } from './store/selectors';
import React, { useEffect } from 'react';
import { NewsActions } from './store/actions';

export const News: React.FC = () => {
  const news = useAppSelector(NewsSelectors.getSortedNews);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(NewsActions.loadNews());
  }, [dispatch]);

  const handleItemDetailsClick = () => {
    dispatch(NewsActions.countUnreadNews());
  };

  return (
    <div>
      <NewsList news={news} onItemDetailsClick={handleItemDetailsClick} />
    </div>
  );
};
