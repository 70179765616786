import styles from './PurchaseConfirmDialog.module.scss';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import SagButton from '../../../components/sag-button/SagButton';
import React from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  purchasePrice: number;
}

export const PurchaseConfirmDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Finish your in-APP purchase</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Confirm and pay kr. {parseFloat((props.purchasePrice / 100).toString()).toFixed(2)}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <SagButton onClick={props.handleClose} value="No" outlined />
        <SagButton onClick={props.handleConfirm} value="Yes" />
      </DialogActions>
    </Dialog>
  );
};
