import styles from './PaymentProcessed.module.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React, { useEffect } from 'react';
import { PaymentActions } from '../store/actions';
import { PaymentSelectors } from '../store/selectors';
import { push } from 'connected-react-router';
import { BarcodeActions } from '../../scanner/store/actions';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';

export const PaymentProcessed: React.FC = () => {
  const paymentRefId = useAppSelector((state) => state.router.location?.query?.paymentRefId);
  const paymentMeans = useAppSelector((state) => state.router.location?.query?.paymentMeans);
  const transactionId = useAppSelector((state) => state.router.location?.query?.transactionId);
  const responseCode = useAppSelector((state) => state.router.location?.query?.responseCode);
  const paymentStatus = useAppSelector(PaymentSelectors.getPaymentStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (responseCode === 'OK') {
      console.log('payment will be confirmed');
      dispatch(
        PaymentActions.sendConfirmPayment({
          paymentRefId,
          paymentMeans,
          transactionId,
        })
      );

      dispatch(BarcodeActions.clearBasket());
    }
    if (responseCode === 'Cancel') {
      dispatch(push('/'));
    }
  }, [dispatch, paymentMeans, paymentRefId, responseCode, transactionId]);

  return (
    <Dialog open={true} className={styles.dialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Box>
        <DialogTitle className={styles.title} id="alert-dialog-title">
          {paymentStatus === 'paid'
            ? 'Your payment is finished'
            : paymentStatus === '3DSFailed'
            ? 'Payment failed'
            : paymentStatus === 'unpaid'
            ? 'Not enough money on the card'
            : 'Your payment is being processed'}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Box component="p" className={styles.dialogContentText}>
            {paymentStatus === 'paid'
              ? 'PAYMENT APPROVED'
              : paymentStatus === '3DSFailed'
              ? 'PAYMENT FAILED'
              : paymentStatus === 'unpaid'
              ? 'UNPAID PAYMENT'
              : 'PLEASE WAIT...'}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
