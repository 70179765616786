import { from } from 'rxjs';
import httpClient from '../app/http-clients/http-client';

const axiosNewsTempConfig = {
  headers: {
    'x-tenant-alias': 'TID-2Y7JRG',
  },
};

export const loadNews = () => from(httpClient.get('/news?current=true', axiosNewsTempConfig));
