import styles from './Footer.module.scss';
import Ripples from 'react-ripples';
import SagButton from '../../../components/sag-button/SagButton';
import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { EmptyBasketDialog } from '../../scanner/empty-basket-dialog/EmptyBasketDialog';

interface Props {
  onMenuClick: () => void;
  title: string;
  subTitle: string;
  isCartButtonEnabled: boolean;
  className?: string;
  onCartButtonClick?: () => void;
  isHidden?: boolean;
  isCalculationInProgress?: boolean;
  handleCloseEmptyBasketDialog: () => void;
  emptyBasketDialogOpen: boolean;
}

export const Footer: React.FC<Props> = (props: Props) => {
  return !props.isHidden ? (
    <>
      <Box className={`${styles.footer} ${props.className}`}>
        <Ripples onMouseDown={props.onMenuClick} className={`${styles.hamburger} material-icons`}>
          menu
        </Ripples>
        <Box className={styles.content}>
          <Box className={styles.contentText}>
            {props.isCalculationInProgress ? <Skeleton /> : <Box className={styles.contentTitle}>{props.title}</Box>}
            <Box className={styles.contentSubTitle}>{props.subTitle}</Box>
          </Box>
        </Box>
        <Box className={styles.cartButtonWrapper}>
          {props.isCartButtonEnabled && <SagButton className={styles.cartButton} value="Buy" onClick={props.onCartButtonClick} />}
        </Box>
      </Box>
      <EmptyBasketDialog open={props.emptyBasketDialogOpen} onCloseClick={props.handleCloseEmptyBasketDialog} />
    </>
  ) : (
    <Box />
  );
};
