import { Barcode, ScanSettings } from 'scandit-sdk';
import { ScanSettingsProvider } from './scan-settings.provider';

export class DefaultScanSettingsProvider implements ScanSettingsProvider {
  get(): ScanSettings {
    return new ScanSettings({
      enabledSymbologies: [
        Barcode.Symbology.CODE128,
        Barcode.Symbology.EAN13,
        Barcode.Symbology.EAN8,
        Barcode.Symbology.UPCA,
        Barcode.Symbology.UPCE,
        Barcode.Symbology.INTERLEAVED_2_OF_5,
        Barcode.Symbology.CODE11,
        Barcode.Symbology.CODE25,
        Barcode.Symbology.CODE32,
        Barcode.Symbology.CODE39,
        Barcode.Symbology.CODE93,
      ],
      codeDuplicateFilter: 3000,
      searchArea: {
        x: 0.25,
        y: 0.43,
        height: 0.05,
        width: 0.5,
      },
    });
  }
}
