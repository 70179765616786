import styles from './SplashEmptyScreen.module.scss';
import React from 'react';
import { Box } from '@mui/material';

const SplashEmptyScreen: React.FC = () => {
  return (
    <Box className={styles.splashContainer}>
      <Box className={styles.tip}>
        <Box className={styles.tipTitle}>Scan items</Box>
        <Box className={styles.tipSubTitle}>Place the barcode in the frame</Box>
      </Box>
      <Box className={styles.tip2}>
        <Box className={styles.title}>Grab a basket, place the bag and start scanning.</Box>
        <Box className={styles.subTitle}>(Remember to scan the bag when it is new)</Box>
      </Box>
    </Box>
  );
};

export default SplashEmptyScreen;
