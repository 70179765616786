import styles from './PaymentFinished.module.scss';
import SagButton from '../../../components/sag-button/SagButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReceiptsSelectors } from '../../receipts/store/selectors';
import { ReceiptsActions } from '../../receipts/store/actions';
import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Box } from '@mui/material';

export const PaymentFinished: React.FC = () => {
  const dispatch = useAppDispatch();
  const receipts = useAppSelector(ReceiptsSelectors.getReceipts);

  useEffect(() => {
    dispatch(ReceiptsActions.loadReceipts());
  }, [dispatch]);

  const handleReceiptClick = () => {
    const receipt = receipts[0];
    if (receipt) {
      dispatch(push(`/receipts/${receipt.id}`));
    }
  };

  const handleFinishClick = () => {
    dispatch(push('/'));
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.status}>
        <Box className={styles.placeholder} />
        <Box className={styles.statusText}>Payment Finished</Box>
      </Box>
      <Box className={styles.text}>
        <Box className={styles.infoText}>
          Thank you for using <br /> Sprinting ScanGo prototype
        </Box>
        <Box className={styles.statusText}>See you next time!</Box>
      </Box>
      <Box className={styles.actions}>
        <SagButton value="Finish" onClick={handleFinishClick} />
        <SagButton value="See receipt" onClick={handleReceiptClick} outlined />
      </Box>
    </Box>
  );
};
