import React from 'react';

const DeleteIcon = (props?: any) => (
  <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.75" y="0.5" width="36.75" height="38" rx="7.5" fill="white" />
    <path d="M26 14.5H12.25" stroke="#5B84BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.25 18.25V23.25" stroke="#5B84BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 18.25V23.25" stroke="#5B84BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M24.75 14.5V26.375C24.75 26.5408 24.6842 26.6997 24.5669 26.8169C24.4497 26.9342 24.2908 27 24.125 27H14.125C13.9592 27 13.8003 26.9342 13.6831 26.8169C13.5658 26.6997 13.5 26.5408 13.5 26.375V14.5"
      stroke="#5B84BA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.25 14.5V13.25C22.25 12.9185 22.1183 12.6005 21.8839 12.3661C21.6495 12.1317 21.3315 12 21 12H17.25C16.9185 12 16.6005 12.1317 16.3661 12.3661C16.1317 12.6005 16 12.9185 16 13.25V14.5"
      stroke="#5B84BA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.75" y="0.5" width="36.75" height="38" rx="7.5" stroke="#E6E6E6" />
  </svg>
);

export default DeleteIcon;
