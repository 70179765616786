import { createReducer } from '@reduxjs/toolkit';
import { PaymentActions } from '../actions';

interface State {
  sessionUrl: string;
  currentPaymentStatus: 'paid' | 'unpaid' | '3DSFailed' | 'pending';
  paymentFinishCountdown: number;
}

const initialState: State = {
  sessionUrl: '',
  currentPaymentStatus: 'pending',
  paymentFinishCountdown: 60,
};

export const paymentReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(PaymentActions.sessionUrlFulfilled, (state, { payload: url }) => ({
      ...state,
      sessionUrl: url,
    }))
    .addCase(PaymentActions.paymentApproved, (state) => ({
      ...state,
      currentPaymentStatus: 'paid',
      paymentFinishCountdown: 60,
    }))
    .addCase(PaymentActions.paymentFailed, (state) => ({
      ...state,
      currentPaymentStatus: '3DSFailed',
    }))
    .addCase(PaymentActions.paymentUnpaid, (state) => ({
      ...state,
      currentPaymentStatus: 'unpaid',
    }))
    .addCase(PaymentActions.setPaymentFinishedCountdown, (state) => ({
      ...state,
      paymentFinishCountdown: state.paymentFinishCountdown - 1,
    }))
);
