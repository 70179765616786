import { createAction } from '@reduxjs/toolkit';
import { featureKey } from '../../index';

export const setFooterTitle = createAction<string>(featureKey + '/setFooterTitle');

export const setFooterSubTitle = createAction<string>(featureKey + '/setFooterSubTitle');

export const setHeaderTitle = createAction<string>(featureKey + '/setHeaderTitle');

export const setFooterCartButtonEnabled = createAction<boolean>(featureKey + '/setFooterCartButtonEnabled');

export const showConfirmCartDialog = createAction(featureKey + '/showConfirmCartDialog');

export const hideConfirmCartDialog = createAction(featureKey + '/hideConfirmCartDialog');

export const setFooterHidden = createAction<boolean>(featureKey + '/setFooterHidden');

export const setCalculationInProgress = createAction<boolean>(featureKey + '/setCalculationInProgress');

export const showSpinner = createAction<boolean>(featureKey + '/showSpinner');
