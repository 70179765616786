import { AppEpic } from '../../../../app/store';
import { BarcodeActions, ShopsActions } from '../actions';
import { BasketService } from '../../../../services';
import { filter, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

export const supportedShopsEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(ShopsActions.getSupportedShops.match),
    switchMap(() =>
      BasketService.supportedShopsGet().pipe(
        mergeMap((response) => of(ShopsActions.shopsFulfilled(response.data), ShopsActions.openShopsPanel()))
      )
    )
  );

export const setCurrentShopEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(ShopsActions.setCurrentShop.match),
    withLatestFrom(state$),
    mergeMap(([, state]) => of(BarcodeActions.postProducts(state.scanner.items), ShopsActions.closeShopsPanel()))
  );
