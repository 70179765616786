import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

const scannerSelector = (state: RootState) => state.scanner;

export const getBasket = createSelector(scannerSelector, (state) => state);

export const getAllProducts = createSelector(scannerSelector, (state) => state.items);

export const getAllProductsDescending = createSelector(scannerSelector, (state) => [...state.items].sort().reverse());

export const getByBarcode = (barcode: string) => createSelector(getAllProducts, (products) => products.find((p) => p.ean === barcode));

export const getTotalPrice = createSelector(scannerSelector, (state) => state.totalBasketPrice);

export const getAllProductsCount = createSelector(scannerSelector, (state) =>
  state.items.map((p) => p.quantity).reduce((accumulator, value) => accumulator + value, 0)
);
