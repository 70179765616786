import { Product } from '../../app/models/Product';

export const handleProductsChange = (products: Product[]): any[] =>
  //there is bug in UI because observable needs time to resolve all the actions
  //when new product is added, it is treated as new element in array which renders additional element
  // which we don't need and then fires one more action that fixes it
  // in this way we do the same on every rerender after it's been read from redux store
  products.reduce((acc: Product[], curr: Product) => {
    const existingItemIndex = acc.findIndex((a) => a?.ean === curr?.ean);
    if (existingItemIndex > -1) {
      //copy object to remove read-only property
      const itemCopy = { ...acc[existingItemIndex] };
      acc[existingItemIndex] = itemCopy;
      return acc;
    }
    return [...acc, curr];
  }, []);
