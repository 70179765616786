import styles from './Receipts.module.scss';
import { ReceiptsList } from './receipts-list/ReceiptsList';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ReceiptsSelectors } from './store/selectors';
import { ReceiptsActions } from './store/actions';
import { Receipt } from './models/receipts.models';
import { push } from 'connected-react-router';

export const Receipts: React.FC = () => {
  const dispatch = useAppDispatch();
  const receipts = useAppSelector(ReceiptsSelectors.getReceipts);

  useEffect(() => {
    dispatch(ReceiptsActions.loadReceipts());
  }, [dispatch]);

  const handleReceiptClick = (receipt: Receipt) => {
    dispatch(push(`/receipts/${receipt.id}`));
  };

  return (
    <div className={styles.receiptsContainer}>
      <ReceiptsList receipts={receipts} onReceiptClick={handleReceiptClick} />
    </div>
  );
};
