import { Card, CardContent, Typography, Fab, Box } from '@mui/material';
import styles from './SplashPermissionTips.module.scss';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';

interface Props {
  onClose: () => void;
}

export const SplashPermissionTips: React.FC<Props> = (props: Props) => (
  <Box className={styles.splashContainer} onClick={() => props.onClose()}>
    <Box className={styles.wrapper}>
      <Card sx={{ minWidth: 275 }} className={styles.mobileTip} onClick={(e) => e.stopPropagation()}>
        <CardContent>
          <Box className={styles.mobileTipHeader}>
            <Typography variant="h5" component="div">
              Give camera permission
            </Typography>
            <Fab color="primary" aria-label="close" className={styles.closeButton} onClick={() => props.onClose()}>
              <CloseRoundedIcon sx={{ fontSize: '35px' }} />
            </Fab>
          </Box>
          <Typography sx={{ mb: 1.5, color: '#767A8E', fontSize: '12px', fontFamily: 'Poppins' }} component={'span'}>
            <Box component="ul" className={styles.list}>
              <Box component="li">
                If Chrome:
                <Box component="ul">
                  <Box component="li">Open Chrome</Box>
                  <Box component="li">At the top right, click More. Settings.</Box>
                  <Box component="li">Click Privacy and security Site settings. Camera or Microphone.</Box>
                  <Box component="li">Select the option you want as your default setting. Review your blocked and allowed sites.</Box>
                </Box>
              </Box>
              <Box component="li">
                If Firefox:
                <Box component="ul">
                  <Box component="li">In the Menu bar at the top of the screen, click Firefox and select Preferences. ...</Box>
                  <Box component="li">Click Privacy & Security from the left menu.</Box>
                  <Box component="li">Scroll down to the Permissions section.</Box>
                  <Box component="li">Click the Settings… ...</Box>
                  <Box component="li">Use the Allow/Block selector to change permission for the website.</Box>
                </Box>
              </Box>
              <Box component="li">
                If Safari:
                <Box component="ul">
                  <Box component="li">Go to Settings</Box>
                  <Box component="li">Go to Websites. </Box>
                  <Box component="li">Select Camera.</Box>
                </Box>
              </Box>
              <Box component="li">
                Else
                <Box component="ul">
                  <Box component="li">Please give the application permission to use camera before you continue using this software.</Box>
                </Box>
              </Box>
            </Box>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  </Box>
);
