import { filter, map, switchMap } from 'rxjs';
import { AppEpic } from '../../../../app/store';
import { ReceiptsActions } from '../actions';
import { ReceiptsService } from '../../../../services';

export const receiptsGetEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(ReceiptsActions.loadReceipts.match),
    switchMap(() => ReceiptsService.receiptsGet().pipe(map((response) => ReceiptsActions.receiptsFulfilled(response.data))))
  );
