import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import styles from './ManualInputDialog.module.scss';

interface Props {
  open: boolean;
  onCloseClick: () => void;
  onApply: (barcode: string) => void;
}

interface ManualBarcodeInputForm {
  barcode: number;
}

const formSchema: yup.SchemaOf<ManualBarcodeInputForm> = yup.object().shape({
  barcode: yup
    .number()
    .test('eanValidator', 'Barcode must be 4 to 13 digits', (value) => {
      if (value) return value.toString().length >= 4 && value.toString().length <= 13;
      return false;
    })
    .required(),
});

const ManualInputDialog = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ManualBarcodeInputForm>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const focus = useCallback(
    (input) => {
      if (props.open) {
        const timeOut = setTimeout(() => {
          input?.focus();
          clearTimeout(timeOut);
        }, 50);
      }
    },
    [props.open]
  );

  const onClose = () => {
    props.onCloseClick();
    reset();
  };

  const onSubmit = (data) => {
    props.onApply(data.barcode.toString());
    onClose();
  };

  return (
    <Dialog
      open={props.open}
      className={styles.dialog}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle className={styles.title} id="alert-dialog-title">
          Enter barcode manually
        </DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Barcode"
            variant="outlined"
            type="number"
            {...register('barcode')}
            inputRef={focus}
            helperText="Field is required and must be 4 to 13 digits"
            error={!!errors.barcode}
            required
            className={styles.textField}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button className={styles.actionButton} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button className={styles.actionButton} autoFocus type="submit">
            Enter Code
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ManualInputDialog;
