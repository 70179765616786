import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import styles from './NewsDetail.module.scss';
import { News } from '../models/News';
import SagButton from '../../../components/sag-button/SagButton';
import { NewsHelpers } from '../../../app/helpers';
import Linkify from 'react-linkify';
import React from 'react';

interface Props {
  open: boolean;
  newsItem: News | undefined;
  onCloseClick: () => void;
}

export const NewsDetail: React.FC<Props> = (props: Props) => {
  return (
    <Dialog open={props.open} onClose={props.onCloseClick} scroll="paper">
      <DialogTitle className={styles.dialogTitle} classes={{ root: styles.dialogTitle }}>
        <div className={styles.dialogTitleDate}>
          {props.newsItem ? NewsHelpers.createdAtDateFilter(props.newsItem.createdAt) : <Skeleton />}
        </div>
        <div className={styles.dialogTitleText}>{props.newsItem ? props.newsItem.textContent.title.en : <Skeleton />}</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText className={styles.dialogContentText}>
          <Linkify>{props.newsItem ? props.newsItem.textContent.message.en : <Skeleton />}</Linkify>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <SagButton className={styles.closeBtn} onClick={props.onCloseClick} value="Close" />
      </DialogActions>
    </Dialog>
  );
};
