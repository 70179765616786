import { AppBar, Dialog, DialogContent, IconButton, InputAdornment, TextField, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import styles from './ShopList.module.scss';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAppSelector } from '../../../app/hooks';
import { ShopsSelectors } from '../store/selectors';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Shop } from '../../../app/models/shop/Shop';
import mockShopList from './mockData.json';

interface Props {
  handleClose: () => void;
  handleChoose: (shop: Shop) => void;
  open: boolean;
}

export const ShopList = (props: Props) => {
  const [shopQuery, setShopQuery] = useState('');

  let shops = useAppSelector(ShopsSelectors.shopsFilter(shopQuery));

  //only for testing/demo purpose, don't push it in production
  const mockData: any = [...shops, ...mockShopList];
  let listKey = '0000';
  //until here remove
  const handleSearchChange = (query: string) => setShopQuery(query);

  return (
    <>
      <Dialog open={props.open} classes={{ paper: styles.dialogPaper, container: styles.container }} onClose={props.handleClose}>
        <DialogContent style={{ overflowY: 'hidden' }} sx={{ padding: '0' }}>
          <AppBar sx={{ position: 'relative', backgroundColor: '#5B84BA' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => props.handleClose()} aria-label="close">
                <CloseRoundedIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Please choose a store
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={styles.dialogMainContent}>
            <div className={styles.searchField}>
              <TextField
                sx={{ width: 'calc(100% - 48px)' }}
                label="Enter a zip code, city or address"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(value) => handleSearchChange(value.target.value)}
                variant="standard"
              />
            </div>
            <div className={styles.shopPlatesContainer}>
              {mockData.map((shop) => {
                //TODO remove this, it's only for testing/demo purpose
                const shopCopy = { ...shop };
                shopCopy.storeId = '00000000-0000-0000-0000-000000009999';
                listKey += '1';
                if (shopCopy.name === 'ScanGo Global') {
                  shopCopy.name = 'Shop KOLDING STORCENTER';
                  shopCopy.address = {
                    zip: '6000',
                    city: 'Kolding',
                    address: 'T W Jensens Stræde 2',
                  };
                }
                //until here

                return (
                  <div key={listKey} className={styles.shopPlate} onClick={() => props.handleChoose(shopCopy)}>
                    <div className={styles.name}>{shopCopy.name}</div>
                    <div className={styles.location}>
                      {shopCopy.address.street} {shopCopy.address.zip} {shopCopy.address.city}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
