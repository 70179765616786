import { Button } from '@mui/material';
import styles from './SagButton.module.scss';
import React from 'react';

interface Props {
  value?: string;
  onClick?: () => void;
  className?: string;
  outlined?: boolean;
}

const SagButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      className={`${props.outlined ? styles.sagOutlinedButton : styles.sagButton} ${props.className ? props.className : ''}`}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      {props.value}
    </Button>
  );
};

export default SagButton;
