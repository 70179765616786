import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import WebFont from 'webfontloader';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { history, store } from './app/store';
import * as ScanditSDK from 'scandit-sdk';
import { SystemHelpers } from './app/helpers';
import { CardsService } from './services';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

(async () => {
  console.log('start init');
  await ScanditSDK.configure(process.env.REACT_APP_SCANDIT_API_KEY, {
    engineLocation: process.env.REACT_APP_SCANDIT_ENGINE_LOCATION,
  });

  if (!SystemHelpers.isDeviceCredentialsGenerated()) {
    CardsService.devicePost(SystemHelpers.generateAndGetDeviceKey(), SystemHelpers.generateAndGetDeviceId());
  }

  SystemHelpers.cacheUserId('7dea57cc-798d-4a4e-8b8a-9b85c49f281c');

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );

  console.log('end init');
})();

WebFont.load({
  google: {
    families: ['Nunito Sans', 'sans-serif', 'Material Icons', 'Roboto'],
  },
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
