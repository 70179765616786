import axios from 'axios';

const headers = {
  Authorization: `Bearer ${process.env.REACT_APP_HEIMDAL_API_TOKEN}`,
  'x-tenant-alias': 'TID-PQHE4X',
};

const httpClient = axios.create({
  headers: headers,
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default httpClient;
