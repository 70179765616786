import styles from './ScanList.module.scss';
import { Product } from '../../../app/models/Product';
import React, { useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Skeleton } from '@mui/material';
import CountControl from '../count-control/CountControl';
import { useAppSelector } from '../../../app/hooks';
import { LayoutSelectors } from '../../layout/store/selectors';
import DeleteIcon from './DeleteIcon';

interface Props {
  products: Product[];
  onIncrementCountClick: (product: Product) => void;
  onDecrementCountClick: (product: Product) => void;
  onDeleteProductClick: (product: Product) => void;
}

const ScanList: React.FC<Props> = (props: Props) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const isCalculationInProgress = useAppSelector(LayoutSelectors.isCalculationInProgress);
  const open = useRef<number>(-1);

  const handleExpandClick = (product: Product, i: number) => {
    if (open.current === i) {
      open.current = -1;
    } else {
      open.current = i;
    }

    if (!selectedProduct || selectedProduct.ean !== product.ean) {
      setSelectedProduct(product);
    } else {
      setSelectedProduct(undefined);
    }
  };

  const priceFormat = (price: number) => {
    return parseFloat((price! / 100).toString()).toFixed(2);
  };

  const deleteProduct = (product: Product) => {
    open.current = -1;
    props.onDeleteProductClick(product);
  };

  return (
    <Box className={styles.listContainer}>
      {props.products.map((p, i) => (
        <Box key={i}>
          <Divider className={styles.divider} />
          <Accordion expanded={open.current === i} className={styles.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
              <Box key={p.ean} className={styles.listItem} onClick={() => handleExpandClick(p, i)}>
                <Box className={styles.itemName}>
                  {p.description ? (
                    <Box>
                      {p.description}
                      <Box className={styles.itemPricePant}>
                        {p.related && p.related.length > 0 ? `+pant ${priceFormat(p.related[0].totalPrice)}` : ''}
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton />
                  )}
                </Box>
                <Box className={styles.itemCount}>{p.quantity ? p.quantity : <Skeleton />} psc</Box>
                <Box className={styles.itemPrice}>
                  {p.totalPrice && !isCalculationInProgress ? priceFormat(p.totalPrice) : <Skeleton />}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={styles.expandedRow}>
                <Box className={styles.placeholder} />
                <CountControl
                  className={styles.countControl}
                  displayNumber={p.quantity}
                  onDecreaseClick={() => props.onDecrementCountClick(p)}
                  onIncreaseClick={() => props.onIncrementCountClick(p)}
                />
                <Box className={styles.actions} onClick={() => deleteProduct(p)}>
                  <DeleteIcon />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
      <Divider className={styles.divider} />
    </Box>
  );
};

export default ScanList;
