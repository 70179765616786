import { AppEpic } from '../../../../app/store';
import { EMPTY, filter, map, switchMap } from 'rxjs';
import { CardsActions } from '../actions';
import { CardsService } from '../../../../services';
import { CardsHelpers } from '../../../../app/helpers';

export const creatingUrlEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(CardsActions.getCreateCardUrl.match),
    switchMap(() => CardsService.cardCreateUrlGet().pipe(map((response) => CardsActions.urlCreateCardFulfilled(response.data.url))))
  );

export const createCardEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(CardsActions.createCard.match),
    switchMap(({ payload: transactionId }) =>
      CardsService.createCardPost(transactionId).pipe(map((response) => CardsActions.createCardFulfilled(response.data.card)))
    )
  );

export const loadSavedCards: AppEpic = (action$) =>
  action$.pipe(
    filter(CardsActions.loadSavedCards.match),
    switchMap(() => CardsService.savedCardsGet().pipe(map((response) => CardsActions.savedCardsFulfilled(response.data))))
  );

export const cachePrimaryCard: AppEpic = (action$) =>
  action$.pipe(
    filter(CardsActions.makeCardPrimary.match),
    switchMap(({ payload: card }) => {
      CardsHelpers.cachePrimaryCard(card.id);
      return EMPTY;
    })
  );
