import { Box, Button } from '@mui/material';
import styles from './CountControl.module.scss';
import React from 'react';

interface Props {
  className?: string;
  displayNumber: number;
  onIncreaseClick?: () => void | undefined;
  onDecreaseClick?: () => void | undefined;
}

const CountControl: React.FC<Props> = (props: Props) => {
  return (
    <Box className={`${styles.actions} ${props.className}`}>
      <Button variant="text" className={styles.button} onClick={() => (props.onDecreaseClick ? props.onDecreaseClick() : undefined)}>
        -
      </Button>
      <Box className={styles.countDisplay}>{props.displayNumber}</Box>
      <Button variant="text" className={styles.button} onClick={() => (props.onIncreaseClick ? props.onIncreaseClick() : undefined)}>
        +
      </Button>
    </Box>
  );
};
export default CountControl;
