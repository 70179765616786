import { createReducer } from '@reduxjs/toolkit';
import { Shop } from '../../../../app/models/shop/Shop';
import { ShopsActions } from '../actions';

interface ShopsState {
  supportedShops: Shop[];
  currentShop: Shop | undefined;
  shopsPanelOpen: boolean;
}

const shopsInitialState: ShopsState = {
  supportedShops: [],
  currentShop: undefined,
  shopsPanelOpen: false,
};

export const shopsReducer = createReducer(shopsInitialState, (builder) =>
  builder
    .addCase(ShopsActions.shopsFulfilled, (state, { payload: shops }) => ({
      ...state,
      supportedShops: shops,
    }))
    .addCase(ShopsActions.setCurrentShop, (state, { payload: shop }) => ({
      ...state,
      currentShop: shop,
    }))
    .addCase(ShopsActions.openShopsPanel, (state) => ({
      ...state,
      shopsPanelOpen: true,
    }))
    .addCase(ShopsActions.closeShopsPanel, (state) => ({
      ...state,
      shopsPanelOpen: false,
    }))
);
