import { createAction } from '@reduxjs/toolkit';
import { featureKey } from '../../index';
import { ConfirmPaymentPayload } from '../models/payment.models';

export const loadPaymentSessionUrl = createAction(featureKey + '/getPaymentSessionUrl');

export const sessionUrlFulfilled = createAction<string>(featureKey + '/sessionUrlFulfilled');

export const sendConfirmPayment = createAction<ConfirmPaymentPayload>(featureKey + '/sendConfirmPayment');

export const confirmingPaymentFailed = createAction(featureKey + '/confirmPaymentFailed');

export const checkPaymentStatus = createAction<ConfirmPaymentPayload>(featureKey + '/checkPaymentStatus');

export const paymentApproved = createAction(featureKey + '/paymentApproved');

export const paymentFailed = createAction(featureKey + '/paymentFailed');

export const paymentUnpaid = createAction(featureKey + '/paymentUnpaid');

export const finishPaymentTimerRedirect = createAction(featureKey + '/finishPaymentTimerRedirect');

export const setPaymentFinishedCountdown = createAction(featureKey + '/setPaymentFinishedCountdown');

export const cancelQRCodeTimer = createAction(featureKey + '/cancelQRCodeTimer');

export const skipPayment = createAction(featureKey + '/skipPayment');
