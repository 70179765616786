import { createAction } from '@reduxjs/toolkit';
import { featureKey } from '../../index';
import { Card } from '../../../../app/models/payment/Card';

export const getCreateCardUrl = createAction(featureKey + '/getCreateCardUrl');

export const urlCreateCardFulfilled = createAction<string>(featureKey + '/urlCreateCardFulfilled');

export const loadSavedCards = createAction(featureKey + '/loadSavedCards');

export const savedCardsFulfilled = createAction<Card[]>(featureKey + '/savedCardsFulfilled');

export const createCard = createAction<string>(featureKey + '/createCard');

export const createCardFulfilled = createAction<Card>(featureKey + '/createCardFulfilled');

export const deleteCard = createAction<Card>(featureKey + '/deleteCard');

export const makeCardPrimary = createAction<Card>(featureKey + '/makeCardPrimary');
