import { from } from 'rxjs';
import httpPaymentClient from '../app/http-clients/http-payment-client';
import { v4 as generateUUID } from 'uuid';
import { SystemHelpers } from '../app/helpers';
import { getCardFormUrl } from '../app/helpers/cards.helpers';

export const cardCreateUrlGet = () => from(httpPaymentClient.get(getCardFormUrl()));

export const createCardPost = (transactionId: string) => {
  const payload = {
    cardAlias: generateUUID(),
    sessionId: transactionId,
    providerType: 'NETAXEPT',
    isActive: true,
    isMigrated: false,
  };

  return from(
    httpPaymentClient.post('/card', payload, {
      headers: {
        'X-Authorization-Device': 'Bearer ' + SystemHelpers.getXAuthorizationDevice(),
        'X-Request-Signature': 'Bearer ' + SystemHelpers.generateJwtPayloadToken(payload),
      },
    })
  );
};

export const devicePost = (deviceKey: string, deviceId: string) =>
  from(
    httpPaymentClient.post('/device', {
      id: deviceId,
      key: deviceKey,
    })
  );

export const savedCardsGet = () => from(httpPaymentClient.get('/card'));
