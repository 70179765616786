import { createReducer } from '@reduxjs/toolkit';
import { Receipt } from '../../models/receipts.models';
import { ReceiptsActions } from '../actions';

interface State {
  receipts: Receipt[];
}

const initialState: State = {
  receipts: [],
};

export const receiptsReducer = createReducer(initialState, (builder) =>
  builder.addCase(ReceiptsActions.receiptsFulfilled, (state, { payload: receipts }) => ({
    ...state,
    receipts: receipts,
  }))
);
