import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

const layoutSelector = (state: RootState) => state.layout;

export const getFooterTitle = createSelector(layoutSelector, (state) => state.footerTitle);

export const getFooterSubTitle = createSelector(layoutSelector, (state) => state.footerSubTitle);

export const getHeaderTitle = createSelector(layoutSelector, (state) => state.headerTitle);

export const getFooterCartButtonEnabled = createSelector(layoutSelector, (state) => state.footerCartButtonEnabled);

export const isConfirmCartDialogVisible = createSelector(layoutSelector, (state) => state.isConfirmCartDialogVisible);

export const isFooterHidden = createSelector(layoutSelector, (state) => state.footerHidden);

export const isCalculationInProgress = createSelector(layoutSelector, (state) => state.calculationInProgress);

export const showSpinner = createSelector(layoutSelector, (state) => state.showSpinner);
