import styles from './EditCardList.module.scss';
import visaIcon from '../../../assets/cards/visa-icon.svg';
import mastercardIcon from '../../../assets/cards/mastercard-icon.svg';
import { IconButton } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { Card } from '../../../app/models/payment/Card';
import React from 'react';

interface Props {
  cards: Card[];
  onPrimaryActionClick: (card: Card) => void;
  onDeleteActionClick: (card: Card) => void;
  primaryCardId: string;
}

export const EditCardList: React.FC<Props> = (props: Props) => {
  const isCardPrimary = (card: Card): boolean => {
    if (props.primaryCardId) {
      return card.id === props.primaryCardId;
    }
    return false;
  };

  return (
    <div className={styles.container}>
      {props.cards.map((card) => (
        <div className={styles.card} key={card.id}>
          <img className={styles.cardImage} src={card.cardType === 'VISA' ? visaIcon : mastercardIcon} alt="Card icon" />
          <div className={styles.info}>
            <div className={styles.title}>{card.cardType}</div>
            <div className={styles.cardNumber}>XXXX XXXX XXXX {card.lastFourDigits}</div>
          </div>
          <div className={styles.actions}>
            <IconButton
              aria-label="delete"
              sx={{ color: 'red' }}
              onClick={() => (props.onDeleteActionClick ? props.onDeleteActionClick(card) : {})}
            >
              <DeleteRoundedIcon />
            </IconButton>
            <IconButton
              aria-label="primary"
              sx={{ color: '#F0991F' }}
              onClick={() => (props.onPrimaryActionClick ? props.onPrimaryActionClick(card) : {})}
            >
              {isCardPrimary(card) ? <StarRoundedIcon /> : <StarBorderRoundedIcon />}
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};
