import { AppEpic } from '../../../../app/store';
import { BasketService } from '../../../../services';
import { delay, filter, ignoreElements, map, mergeMap, switchMap, take, takeUntil, timer, withLatestFrom } from 'rxjs';
import { PaymentActions } from '../actions';
import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { BarcodeActions } from '../../../scanner/store/actions';

export const loadPaymentSessionUrlEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(PaymentActions.loadPaymentSessionUrl.match),
    withLatestFrom(state$),
    switchMap(([, state]) => BasketService.paymentStartPost(state.scanner).pipe(map((resp) => (window.location.href = resp.data.url)))),
    ignoreElements()
  );

export const skipPaymentEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(PaymentActions.skipPayment.match),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      BasketService.confirmPaymentLocally(state.scanner).pipe(
        map((resp) => resp.data.status),
        mergeMap((status) =>
          status === 'paid'
            ? [PaymentActions.paymentApproved(), BarcodeActions.clearBasket(), push('/payment-approved')]
            : [PaymentActions.paymentUnpaid()]
        )
      )
    )
  );

export const confirmPaymentEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(PaymentActions.sendConfirmPayment.match),
    switchMap(({ payload }) =>
      BasketService.paymentConfirmPost(payload).pipe(
        map((resp) => (resp.data.success ? PaymentActions.checkPaymentStatus(payload) : PaymentActions.confirmingPaymentFailed()))
      )
    )
  );

export const checkPaymentStatus: AppEpic = (action$) =>
  action$.pipe(
    filter(PaymentActions.checkPaymentStatus.match),
    switchMap(({ payload }) =>
      BasketService.paymentStatusGet(payload.paymentRefId).pipe(
        map((resp) => resp.data.status),
        mergeMap((status) =>
          status === 'paid'
            ? [PaymentActions.paymentApproved(), push('/payment-approved')]
            : status === 'unpaid'
            ? [PaymentActions.paymentUnpaid()]
            : status === '3DSFailed'
            ? [PaymentActions.paymentFailed()]
            : [PaymentActions.checkPaymentStatus(payload)]
        ),
        delay(3000)
      )
    )
  );

export const QRCodeTimerEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(PaymentActions.finishPaymentTimerRedirect.match),
    switchMap(() =>
      timer(60 * 1000).pipe(
        map(() => push('/payment-finished')),
        takeUntil(action$.pipe(ofType(PaymentActions.cancelQRCodeTimer)))
      )
    )
  );

export const QRCodeTimerCountdownEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(PaymentActions.finishPaymentTimerRedirect.match),
    switchMap(() =>
      timer(0, 1000).pipe(
        take(60),
        map(() => PaymentActions.setPaymentFinishedCountdown()),
        takeUntil(action$.pipe(ofType(PaymentActions.cancelQRCodeTimer)))
      )
    )
  );
