import { createReducer } from '@reduxjs/toolkit';
import { News } from '../../models/News';
import { NewsActions } from '../actions';
import { NewsHelpers } from '../../../../app/helpers';

interface State {
  news: News[];
  unreadNewsCount;
}

const initialState: State = {
  news: [],
  unreadNewsCount: 0,
};

export const newsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(NewsActions.newsFulfilled, (state, { payload: news }) => ({
      ...state,
      news: news,
    }))
    .addCase(NewsActions.countUnreadNews, (state) => ({
      ...state,
      unreadNewsCount: NewsHelpers.countUnreadNews(state.news),
    }))
);
